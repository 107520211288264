import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

export const configureDataDog = () => {
  const { hostname } = window.location;
  const hostnameEnvMap: Record<string, string> = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'uat-arbor.trellisrx.com': 'uat',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'arbor.trellisrx.com': 'prod',
  };
  const env = hostnameEnvMap[hostname];
  const defaultOptions: Partial<RumInitConfiguration> & { enableSessionReplayRecording: boolean } = {
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
    enableSessionReplayRecording: true,
  };
  const envOptions: Record<string, Partial<RumInitConfiguration> & { enableSessionReplayRecording: boolean }> = {
    uat: {
      ...defaultOptions,
      defaultPrivacyLevel: 'allow',
    },
  };

  if (env) {
    const { enableSessionReplayRecording, ...options } = envOptions[env] || defaultOptions;
    datadogRum.init({
      applicationId: '<DD_APPLICATION_ID>',
      clientToken: '<DD_CLIENT_TOKEN>',
      site: 'datadoghq.com',
      service: 'tcms-ui',
      env,
      version: '2bc84d57c64c7cb658716f0f51b25d1b3b65f802',
      allowedTracingUrls: ['', /https:\/\/.*\.trellisrx\.com/],
      ...options,
    });

    if (enableSessionReplayRecording) {
      datadogRum.startSessionReplayRecording();
    }
  }
};
