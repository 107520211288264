import React, { useState, useCallback, useEffect } from 'react';
import {
  Paper,
  TextField,
  ClickAwayListener,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  Button,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import lodash from 'lodash';
import Validation from 'components/form/validation/validation';
import { Icon } from 'react-icons-kit';
import { ic_history as icHistory } from 'react-icons-kit/md/';

import FieldLabel from './field-label';

const useStyles = makeStyles(theme => ({
  dropdown: {
    position: 'absolute',
    zIndex: 1,
    maxHeight: 500,
    minWidth: 200,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  minigridRow: {
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
      cursor: 'pointer',
    },
    'minWidth': 0,
    'height': 10,
  },
  minigridRowDisabled: {
    '&:hover': {
      cursor: 'not-allowed',
    },
    'minWidth': 0,
    'height': 10,
  },
  minigridCellDisabled: {
    padding: theme.spacing(1),
    color: theme.palette.primary.lightGrey,
  },
  minigridRowKeyboard: {
    backgroundColor: theme.palette.primary.hover,
    minWidth: 0,
    height: 10,
  },
  cell: {
    padding: theme.spacing(1),
    color: theme.palette.primary.black,
  },
  cellHighlighted: {
    padding: theme.spacing(1),
    color: theme.palette.primary.black,
  },
  minigridCols: {
    padding: theme.spacing(1),
    minWidth: 125,
    height: 0,
  },
  minigridTable: {
    maxHeight: 500,
  },
  minigridTableHeader: {
    height: 0,
    padding: 0,
  },
  historyIcon: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    color: theme.palette.primary.black,
    size: 20,
  },
  mainDisplayString: {
    minWidth: 150,
    fontSize: theme.font.mediumFontSize,
    fontWeight: theme.font.weight.medium,
    color: theme.palette.primary.black,
  },
  secondaryDisplayString: {
    minWidth: 200,
    fontSize: theme.font.smallFontSize,
    fontWeight: theme.font.weight.regular,
    paddingRight: theme.spacing(4),
  },
}));

const AutocompleteMinigrid = props => {
  const {
    label,
    labelVariant,
    fetchOptions,
    hint,
    minSearchLength,
    input,
    'meta': { touched, error, warning },
    columnsToShow = { label: 'Result' },
    noHeaders,
    valueFormatter,
    clearable,
    simplistic,
    qaId,
    recentOptions,
    onChange,
    labelField = 'label',
    labelWrapperClass = null,
    'data-qa-id': dataQaId,
    disabled = false,
    resetValue = false,
    defaultValue = '',
  } = props;

  if (Array.isArray(input.value) && resetValue && options && options.length > 0) {
    input.value.label = ' ';
    setOptions([]);
  }
  const [currentValueText, setCurrentValueText] = useState(
    input.value && input.value[labelField] ? input.value[labelField] : defaultValue,
  );

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState(currentValueText);
  const [open, setOpen] = useState(false);
  const [highlightIndex, setHighlightIndex] = useState(0);

  useEffect(() => {
    if (input.value && input.value[labelField]) {
      setSearchText(input.value[labelField]);
      setCurrentValueText(input.value[labelField]);
    }
  }, [input.value]);

  const debouncedFetch = useCallback(
    lodash.debounce(q => {
      if (q) {
        const canSearch = !minSearchLength || q.length >= minSearchLength;
        if (canSearch) {
          setLoading(true);
          fetchOptions(q)
            .then(newOptions => {
              setOptions(newOptions);
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        } else if (recentOptions) {
          setOptions(recentOptions.map(option => ({ ...option, recent: true })));
        }
      } else if (recentOptions) {
        setOptions(recentOptions.map(option => ({ ...option, recent: true })));
      }
    }, 500),
    [],
  );

  const handleListClick = option => {
    input.onChange(option);
    if (onChange) {
      onChange(option);
    }
    setSearchText(option.label);
    setCurrentValueText(option.label);
    setOpen(false);
  };

  const handleSearchFocus = () => {
    setOpen(true);
    debouncedFetch(searchText);
  };

  const handleClickAway = () => {
    setOpen(false);
    setSearchText(currentValueText);
  };

  const handleSearchBlur = () => {
    setOpen(false);
  };

  const handleSearchOnChange = e => {
    setSearchText(e.target.value);
    debouncedFetch(e.target.value);
  };

  const handleSearchClear = () => {
    setSearchText('');
    setCurrentValueText('');
    setOpen(false);
    input.onChange(null);
    if (onChange) {
      onChange(null);
    }
  };

  const handleSearchKeydown = e => {
    if (!open) {
      return;
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (highlightIndex === 0) {
        setHighlightIndex(options.length - 1);
      } else {
        setHighlightIndex(highlightIndex - 1);
      }
    }
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (highlightIndex === options.length - 1) {
        setHighlightIndex(0);
      } else {
        setHighlightIndex(highlightIndex + 1);
      }
    }
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (highlightIndex > -1 && options[highlightIndex]) {
        e.preventDefault();
        handleListClick(options[highlightIndex]);
        setHighlightIndex(0);
      }
    }
  };

  let message = '';
  if (!searchText) {
    message = hint || 'Enter your search term';
  } else if (!options.length) {
    if (minSearchLength) {
      if (searchText && searchText.length < minSearchLength) {
        message = `Enter at least ${minSearchLength} characters to search`;
      }
    } else if (loading) {
      message = 'Searching...';
    } else {
      message = 'Search Term not found';
    }
  }

  const renderTableHeader = () => {
    let content = null;
    if (message) {
      content = <TableCell className={classes.minigridCols}>{message}</TableCell>;
    } else {
      if (simplistic || noHeaders) {
        return null;
      }
      content = Object.keys(columnsToShow).map(columnKey => (
        <TableCell className={classes.minigridCols} key={columnKey}>
          {columnsToShow[columnKey]}
        </TableCell>
      ));
    }
    return (
      <TableHead classes={{ root: classes.minigridTableHeader }}>
        <TableRow className={classes.minigridRow}>{content}</TableRow>
      </TableHead>
    );
  };

  const getOptionKey = (option, index) => option.id || `${option.name}-${index}`;
  const getRowStyle = (option, index) => {
    const isDisabled = option.disabled;
    const isKeyboardHighlighted = highlightIndex === index;
    if (isDisabled) {
      return classes.minigridRowDisabled;
    }
    if (isKeyboardHighlighted) {
      return classes.minigridRowKeyboard;
    }
    return classes.minigridRow;
  };
  const getCellStyle = option => (option.disabled ? classes.minigridCellDisabled : classes.cell);

  const renderTableRows = () => {
    if (!options?.length) {
      return null;
    }
    return (
      <TableBody>
        {options.map((option, index) => {
          const isDisabled = option.disabled;

          const rowStyle = getRowStyle(option, index);
          const cellStyle = getCellStyle(option);
          let row;

          if (simplistic) {
            row = (
              <TableCell
                className="qa-option"
                aria-label={option.label}
                data-qa-id={`option-${option.label}`}
              >
                {option.recent && (
                  <Typography component="span" className={classes.historyIcon}>
                    <Icon icon={icHistory} size={20} />
                  </Typography>
                )}
                <Typography component="span" className={classes.mainDisplayString}>
                  {option.label}
                  {' - '}
                </Typography>
                <Typography component="span" className={classes.secondaryDisplayString}>
                  {option.description}
                </Typography>
              </TableCell>
            );
          } else {
            row = Object.keys(columnsToShow).map(columnKey => {
              const value = valueFormatter
                ? valueFormatter(option, columnKey, option[columnKey])
                : option[columnKey];
              return (
                <TableCell
                  className={`${cellStyle} qa-option`}
                  key={columnKey}
                  aria-label={value}
                  data-qa-id={`option-${value}`}
                >
                  {value}
                </TableCell>
              );
            });
          }

          return (
            <TableRow
              className={rowStyle}
              key={getOptionKey(option, index)}
              onMouseDown={isDisabled ? e => e.preventDefault() : () => handleListClick(option)}
            >
              {row}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
      <div onKeyDown={event => handleSearchKeydown(event)} role="presentation" data-qa-id={qaId}>
        <div className={labelWrapperClass}>
          <FieldLabel variant={labelVariant} fieldName={label} />
        </div>
        <TextField
          variant="standard"
          fullWidth
          {...input}
          onChange={handleSearchOnChange}
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
          placeholder={hint}
          value={searchText}
          InputProps={{
            endAdornment: clearable && input.value && (
              <InputAdornment position="end">
                <Button onClick={handleSearchClear}>
                  <CloseIcon />
                </Button>
              </InputAdornment>
            ),
          }}
          data-qa-id={dataQaId}
          disabled={disabled}
        />

        {open && (
          <Paper className={classes.dropdown}>
            <Table className={classes.minigridTable}>
              {renderTableHeader()}
              {renderTableRows()}
            </Table>
          </Paper>
        )}
        <Validation touched={touched} error={error} warning={warning} />
      </div>
    </ClickAwayListener>
  );
};

export default AutocompleteMinigrid;
