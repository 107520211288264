import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import HTTP from 'services/http';
import uuidV4 from 'uuid/v4';
import { FCPaymentOptions } from 'constants/fc-refill-payment-choice';
import CompletedPage from './pages/completed-page';
import InitialPage from './pages/initial-page';
import ChangesNeededPage from './pages/changes-needed-page';
import QuestionsPage from './pages/questions-page';
import FinalPage from './pages/final-page';
import EmptyOrderFinalPage from './pages/empty-order-final-page';
import InvalidPage from './pages/invalid-page';
import LoadingPage from './pages/loading-page';
import DataCollectPage from './pages/data-collect-page';
import ErrorPage from './pages/error-page';
import answers from './answer_template';
import TransitionPage from './pages/questionnaire-transition-page';
import { STRINGS } from './fc_ss_constants';

const INITIAL_PAGE = 0;
const QUESTIONS_PAGE = 1;
const FINAL_PAGE = 2;
const CHANGES_NEEDED_PAGE = 3;
const INVALID_PAGE = 4;
const COMPLETED_PAGE = 6;
const DOB_MISMATCH_PAGE = 7;
const QUESTIONNAIRE_TRANSITION_PAGE = 8;
const DATA_COLLECT_PAGE = 9;
const EMPTY_ORDER_FINAL_PAGE = 10;

const ancillary_supplies_list = {
  sharps_container: false,
  alcohol_preps: false,
  adhesive_bandages: false,
  not_needed: false,
};

const isProduction = ['arbor.trellisrx.com', 'staging-arbor.trellisrx.com'].includes(
  window.location.hostname,
);
const Refills = () => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(null);
  const { urlShorten } = useParams();
  const [patientAnswers, setPatientAnswers] = useState(answers);
  const [answerOptions, setAnswerOptions] = useState(null);
  const [pharmacyInfo, setPharmacyInfo] = useState({});
  const [patientDob, setPatientDob] = useState(null);
  const [authCount, setAuthCount] = useState(0);
  const [remainingAuthAttempts, setRemainingAuthAttempts] = useState(3);
  const [onlyAncillaryTherapies, setOnlyAncillaryTherapies] = useState(false);
  const [dataCollects, setDataCollects] = useState([]);

  const onError = useCallback((message, ex) => {
    const uuid = uuidV4().substr(0, 6);
    if (error && error.message) {
      // eslint-disable-next-line no-console
      console.error(`${uuid} - ${ex.message} - ${ex.stack}`);
    } else {
      // eslint-disable-next-line no-console
      console.error(`${uuid} - ${ex}`);
    }
    setError({
      message,
      uuid,
    });
  }, []);

  const setPageTypeFromData = data => {
    setLoading(false);
    if ((!data.isValid && !data.completedDateTime) || !data) {
      setPharmacyInfo(data.pharmacyInfo || {});
      setPage(INVALID_PAGE);
      return;
    }

    if (data.completedDateTime) {
      setPage(COMPLETED_PAGE);
      return;
    }

    if (!data.answerOptions) {
      if (data.error === STRINGS.dob_error) {
        setPage(DOB_MISMATCH_PAGE);
      } else {
        setRemainingAuthAttempts(data.remaining_auth_attempts);
        setPharmacyInfo(data.pharmacyInfo || {});
        setPage(INITIAL_PAGE);
      }
      return;
    }
    const onlyAncillaryTherapies = !data.answerOptions.therapies.some(
      therapy => therapy.is_specialty,
    );
    setOnlyAncillaryTherapies(onlyAncillaryTherapies);
    setPharmacyInfo(data.pharmacyInfo || {});
    setPatientAnswers({
      ...patientAnswers,
      therapies: data.answerOptions.therapies,
      ancillary_supplies: ancillary_supplies_list,
    });
    setAnswerOptions(data.answerOptions);
    if (data.dataCollects.length) {
      setDataCollects(data.dataCollects);
    }
    if (data.dataCollects.length) {
      setPage(QUESTIONNAIRE_TRANSITION_PAGE);
    } else {
      setPage(QUESTIONS_PAGE);
    }
  };

  const submitAnswers = async answers => {
    setPatientAnswers(answers);
    const workingAnswers = { ...answers };
    if (workingAnswers.empty_order) {
      // if the patient removed all therapies then they could not
      // have answered any other questions, so force proper values.
      workingAnswers.payment_method_id = null;
      workingAnswers.pickup_date = null;
      workingAnswers.delivery_date = null;
      workingAnswers.delivery_method = null;
      workingAnswers.therapies = answers.therapies;
      workingAnswers.patient_requested_therapy_changes = 1;
      workingAnswers.ancillary_supplies = {
        sharps_container: null,
        alcohol_preps: null,
        adhesive_bandages: null,
        not_needed: null,
      };
      delete workingAnswers.pickup_or_delivery;
      delete workingAnswers.empty_order;
    } else if (workingAnswers.patient_contact_required) {
      // if the patient removed a non-prn, specialty therapy then they could not
      // have answered any other questions, so force proper values.
      workingAnswers.payment_method_id = FCPaymentOptions.CallPatient;
      workingAnswers.pickup_date = null;
      workingAnswers.delivery_date = null;
      workingAnswers.delivery_method = null;
      workingAnswers.therapies = answers.therapies;
      workingAnswers.patient_requested_therapy_changes = 1;
      workingAnswers.ancillary_supplies = {
        sharps_container: null,
        alcohol_preps: null,
        adhesive_bandages: null,
        not_needed: null,
      };
      delete workingAnswers.pickup_or_delivery;
    } else {
      if (!answerOptions.isColdChain) {
        workingAnswers.ancillary_supplies = {
          sharps_container: null,
          alcohol_preps: null,
          adhesive_bandages: null,
          not_needed: null,
        };
      }
      if (workingAnswers.payment_method.answerOptionsIndex > -1) {
        workingAnswers.payment_method_id =
          answerOptions.payment[workingAnswers.payment_method.answerOptionsIndex].id;
      } else {
        workingAnswers.payment_method_id = workingAnswers.payment_method.answerOptionsIndex;
      }
      if (workingAnswers.pickup_or_delivery === STRINGS.delivery) {
        if (workingAnswers.preferred_shipping_address.answerOptionsIndex > -1) {
          const a_index = workingAnswers.preferred_shipping_address.answerOptionsIndex;
          const item = answerOptions.addresses[a_index];
          const zip = item.zip;
          workingAnswers.delivery_state = item.state;
          if (answerOptions?.delivery_methods?.[zip]) {
            workingAnswers.delivery_method = answerOptions.delivery_methods[zip].method;
          } else {
            workingAnswers.delivery_method = null;
          }
          workingAnswers.preferred_shipping_address = `${answerOptions.patientName} - ${
            item.use
          } - ${item.line1}${item.line2 ? ` ${item.line2}` : ''}, ${item.city}, ${item.state}, ${
            item.zip
          }`;
          if (
            answerOptions?.delivery_dates &&
            answerOptions.delivery_dates[item.zip] &&
            answerOptions.delivery_dates[item.zip].length === 0
          ) {
            workingAnswers.delivery_date = null;
          }
        } else {
          workingAnswers.preferred_shipping_address = null;
          workingAnswers.delivery_date = null;
        }
        workingAnswers.pickup_date = null;
      } else {
        workingAnswers.delivery_method = null;
        workingAnswers.signature_required = null;
        workingAnswers.preferred_shipping_address = null;
        workingAnswers.delivery_date = null;
      }
    }

    delete workingAnswers.payment_method;
    workingAnswers.dp_name = answerOptions.dp_name;
    workingAnswers.dp_npi = answerOptions.dp_npi;
    workingAnswers.dp_method = answerOptions.dp_method;

    const payload = { dob: patientDob, answers: workingAnswers };
    await HTTP.post(`/public/fc-patient-submit/${urlShorten}`, payload);
    if (!answers.patient_contact_required && !answers.empty_order) {
      setPage(FINAL_PAGE);
    }
  };
  React.useEffect(() => {
    let payload = { patientDob };
    if (authCount === 0 && !isProduction) {
      const urlParams = new URLSearchParams(window.location.search);
      const forcedDob = urlParams.get('dob');
      if (forcedDob) {
        setPatientDob(forcedDob);
        payload = { patientDob: forcedDob };
      }
    }
    setLoading(true);
    HTTP.post(`/public/refill-questionnaires/${urlShorten}`, payload)
      .then(response => {
        try {
          const { data } = response;
          setPageTypeFromData(data);
        } catch (e) {
          onError('Failed to load the questionnaire', e);
        } finally {
          setReady(true);
        }
      })
      .catch(ex => {
        onError('The questionnaire was not found', ex);
      });
  }, [authCount, urlShorten]);

  if (error) {
    return <ErrorPage message={error.message} uuid={error.uuid} />;
  }
  if (!ready) {
    return <LoadingPage />;
  }

  const store = {
    pharmacyInfo,
    answerOptions,
    patientAnswers,
    patientDob,
    onlyAncillaryTherapies,
    dataCollects,
  };

  return (
    <>
      {(page === INITIAL_PAGE || page === DOB_MISMATCH_PAGE) && (
        <InitialPage
          updateDob={dob => {
            setPatientDob(dob);
          }}
          onNext={() => {
            setAuthCount(authCount + 1);
          }}
          remainingAuthAttempts={remainingAuthAttempts}
          dobValue={patientDob}
          store={store}
          hasBirthDateError={page === DOB_MISMATCH_PAGE}
        />
      )}
      {page === CHANGES_NEEDED_PAGE && <ChangesNeededPage store={store} submit={submitAnswers} />}
      {page === QUESTIONS_PAGE && (
        <QuestionsPage skipToPage={setPage} submit={submitAnswers} store={store} />
      )}
      {page === COMPLETED_PAGE && <CompletedPage />}
      {page === FINAL_PAGE && <FinalPage store={store} />}
      {page === INVALID_PAGE && <InvalidPage store={store} />}
      {page === QUESTIONNAIRE_TRANSITION_PAGE && (
        <TransitionPage
          continue={() => setPage(DATA_COLLECT_PAGE)}
          questionnaire_count={store?.dataCollects?.length || 0}
        />
      )}
      {page === DATA_COLLECT_PAGE && (
        <DataCollectPage setPage={setPage} data_collects={dataCollects} />
      )}
      {page === EMPTY_ORDER_FINAL_PAGE && (
        <EmptyOrderFinalPage store={store} submit={submitAnswers} />
      )}
      {loading && <LoadingPage />}
    </>
  );
};

export default Refills;
