import React from 'react';
import { Button, Box, Typography, Chip, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import useStore from 'hooks/useStore';
import { getFirstCategoryOfDataType } from 'services/utils/data-collect';
import { convertToArborDate } from 'models/time/arbor-date';
import moment from 'moment';
import { useIcdGroup } from 'containers/patient/clinical/use-cdm-group';
import { ClinicalDataItem } from 'interfaces/clinical-data/ClinicalDataResponse';
import { IClinicalDataType } from 'interfaces/redux/IClinicalDataType';
import { IState } from 'interfaces/redux/IState';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { windowFeatureIsEnabled } from 'config/window-features';
import { notifyError } from 'actions/action-notifications';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { ITask } from 'interfaces/redux/ITasks';
import {
  selectSelectedTasks,
  selectSelectedWagTasks,
} from 'containers/patient/tasks-new/selectors';
import {
  selectTaskKey,
  setWagSelectedTasks,
  clearWagSelectedTask,
  excludeSelectedTask,
} from 'actions/action-tasks';
import { getTaskKey } from 'containers/patient/tasks-new/tasks-table/utils';
import { ICdmGroupHelper, IFormValues, IRenderProps } from '../interfaces/interfaces';
import useStyles from './render-work-as-group-styles';
import { logger } from '../../../winston-logger';
import { DC, INT } from '../../../constants/index';

/**
 * Get combined item values given item values from multiple DC's.
 * When 2 DC's have the same clinical data type:
 * - use the one with the most recent assessment date.
 * - if they have same assessment date, pick one prioritizing current DC task.
 * - if one is "cannot complete reason" then pick the other one, regardless of the assessment date
 * - if at least one of them is required, then set as required
 *
 * @param {*} currentItemValues the current item values
 * @param {*} fieldIds the ID's of selected lab fields
 * @param {*} itemsToAdd the item values that belong to the other DC's
 * @returns an object with the resulting item values
 */
export const getCombinedItemValues = (
  currentItemValues: IFormValues,
  fieldIds: string[],
  itemsToAdd: ClinicalDataItem[],
): IFormValues => {
  const result: IFormValues = {
    'additional-field': [...fieldIds],
    ...currentItemValues,
  };

  itemsToAdd.forEach(item => {
    const cdtId = item.clinicalDataTypeId;

    let existingItemDate = result[`${cdtId}-date`];

    // if assessment date is moment (just set prior to saving)
    // then make it string like the rest of the dates
    if (moment.isMoment(existingItemDate)) {
      result[`${cdtId}-date`] = convertToArborDate(existingItemDate).getCustomerDate(false);
      existingItemDate = result[`${cdtId}-date`];
    }

    const existingItemCannotComplete = result[`${cdtId}-cannot-complete-reason`];

    const isItemWorthComparing = !item.cannotCompleteReason && item.assessmentDate;
    const isExistingWorthComparing = !existingItemCannotComplete && existingItemDate;

    if (isExistingWorthComparing && isItemWorthComparing) {
      // compare dates and leave most recent
      if (new Date(item.assessmentDate as unknown as string) > new Date(existingItemDate)) {
        result[`${cdtId}-date`] = item.assessmentDate;
        result[`${cdtId}-value`] = item.value;
      }
    } else if (isItemWorthComparing || !existingItemDate) {
      result[`${cdtId}-id`] = result[`${cdtId}-id`] || item.id;
      result[`${cdtId}-required`] = item.required;
      result[`${cdtId}-date`] = item.assessmentDate;
      result[`${cdtId}-value`] = item.value;
      result[`${cdtId}-cannot-complete-reason`] = item.cannotCompleteReason;
    }

    // set required if at least 1 of all DC's is required
    result[`${cdtId}-required`] =
      result[`${cdtId}-required`] ||
      itemsToAdd.filter(it => it.clinicalDataTypeId === cdtId).some(it => it.required);
  });

  return result;
};

/**
 * Add DC items of other tasks in current active form by dispatching redux actions.
 *
 * @param {object} icdGroup the response with the information about the CDM group
 * @param {*} taskId the current DC task ID
 * @param {*} clinicalDataItems all patient clinical data items
 * @param {*} state the whole state
 * @param {*} clinicalDataTypes the list of clinical data types
 * @param {*} dispatch the dispatch handler
 * @param {*} formId the redux form ID
 */
export const addLabsFromOtherTasks = (
  icdGroup: ICdmGroupHelper,
  taskId: number,
  clinicalDataItems: ClinicalDataItem[],
  state: IState,
  clinicalDataTypes: Record<number, IClinicalDataType>,
  dispatch: Function,
  formIdPrefix: string,
) => {
  const otherTaskIds = icdGroup.taskIds.filter(id => id !== taskId);
  const itemsToAdd = (clinicalDataItems || []).filter(item =>
    otherTaskIds.includes(item.taskDataCollectId as number),
  );

  const formId = `${formIdPrefix}-data_collect-data_collect`;
  const formValues: IFormValues = getFormValues(formId)(state);

  const fieldIds = formValues['additional-field'];
  const currentItemValues = fieldIds.reduce((acc: IFormValues, fieldId: string) => {
    const clinicalDataTypeId = fieldId.split('-')[1];
    Object.keys(formValues).forEach(valueKey => {
      if (valueKey.startsWith(`${clinicalDataTypeId}-`)) {
        acc[valueKey] = formValues[valueKey];
      }
      return acc;
    });
    return acc;
  }, {});

  itemsToAdd.forEach(item => {
    const category = getFirstCategoryOfDataType(
      clinicalDataTypes,
      item.clinicalDataTypeId,
    ).categoryId;
    const fieldId = `${category}-${item.clinicalDataTypeId}`;
    if (!fieldIds.includes(fieldId)) {
      fieldIds.push(fieldId);
    }
  });

  const valuesToDispatch = getCombinedItemValues(currentItemValues, fieldIds, itemsToAdd);

  Object.keys(valuesToDispatch).forEach(valueKeyToDispatch => {
    let valueToDispatch = valuesToDispatch[valueKeyToDispatch];
    // if value is date, convert it first
    if (valueKeyToDispatch.endsWith('-date')) {
      valueToDispatch = convertToArborDate(valueToDispatch, false).getUtcDatetime();
    }
    dispatch(change(formId, valueKeyToDispatch, valueToDispatch));
  });
};

const RenderWorkAsGroup = ({ providers, input, disabled, formId }: IRenderProps) => {
  const [workAsGroup, setWorkAsGroup] = React.useState<boolean>(false);
  const [excludedTherapyIds, setExcludedTherapyIds] = React.useState<number[]>([]);
  const [specialtyTypeName, setSpecialtyTypeName] = React.useState<string>('');
  const taskId = providers.task.id;
  const [patientId, therapies, tasks] = useStore(
    'patient.id',
    'therapies.data',
    'tasks.data',
    'lookups.clinicalDataTypes',
  );
  const selectedTasks = useTypedSelector(selectSelectedTasks);
  const selectedWagTaskList = useTypedSelector(selectSelectedWagTasks);
  const selectedWagTaskKeys = useTypedSelector(state => state.tasks.selectedWagTaskKeys);
  const listOfTasks = useTypedSelector(state => state.tasks.data);
  const clinicalData = useTypedSelector(state => state.clinicalData);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { therapy } = providers.dataCollectInformation;

  const icdGroup = useIcdGroup(
    patientId,
    therapy ? therapy.id : null,
    // eslint-disable-next-line dot-notation
    tasks[`DC${taskId}`]['status_id'],
  );

  React.useEffect(() => {
    const onChangeTherapyIds = icdGroup.therapyIds.filter(t => !excludedTherapyIds.includes(t));
    if (
      icdGroup.therapyIds.length > 0 &&
      icdGroup.therapyIds.every(x => excludedTherapyIds.includes(x))
    ) {
      setWorkAsGroup(false);
      setExcludedTherapyIds(icdGroup.therapyIds);
      dispatch(change(formId, 'work_as_group_tasks', []));
      dispatch(change(formId, 'work_as_group', []));
    }

    if (
      icdGroup.therapyIds.length > 0 &&
      icdGroup.therapyIds.length - excludedTherapyIds.length === 1
    ) {
      setWorkAsGroup(false);
      setExcludedTherapyIds(excludedTherapyIds);
      dispatch(change(formId, 'work_as_group_tasks', []));
      dispatch(change(formId, 'work_as_group', []));
    }
    input.onChange(onChangeTherapyIds);
  }, [excludedTherapyIds]);

  React.useEffect(() => {
    (async () => {
      // eslint-disable-next-line
      if (therapy?.diagnosis_code !== undefined) {
        try {
          const response = await ApplicationManagerClient.fetchDiseaseStatesNameByDiagnosisCode(
            therapy.diagnosis_code,
          );
          if (response.data.length > 0) {
            const specialtyName = response.data[0].name;
            setSpecialtyTypeName(specialtyName);
          }
        } catch (err) {
          logger.error(err);
          dispatch(notifyError('Unable to fetch Specialty Type Name.'));
        }
      }
    })();
  }, [therapy]);

  React.useEffect(() => {
    if (!workAsGroup && selectedWagTaskKeys && selectedWagTaskKeys.length) {
      setWorkAsGroup(true);
    }
  }, [workAsGroup]);

  const calcWorkAsGroupTasks = () =>
    icdGroup && icdGroup.taskIds && icdGroup.taskIds.length > 0
      ? icdGroup.taskIds.map(taskId => {
          const taskTherapyId: number | null = tasks[`DC${taskId}`]['therapy_id'] || null; // eslint-disable-line
          const taskServiceGroupId: number | null =
            tasks[`DC${taskId}`]['service_group_id'] || null; // eslint-disable-line
          return {
            task_id: taskId,
            therapy_id: taskTherapyId,
            service_group_id: taskServiceGroupId,
          };
        })
      : [];

  const updateWagSelectedTaskkeys = (tasks: ITask[]) => {
    dispatch(setWagSelectedTasks(tasks.map(getTaskKey)));
  };

  const getWorkAsGroupTasksToBeAdded = (
    groupedTasks: { task_id: number; therapy_id: number }[],
  ): ITask[] => {
    return groupedTasks.reduce((acc, t) => {
      if (
        !selectedTasks.some(
          selectedTask =>
            selectedTask.taskType === DC &&
            selectedTask.id === t.task_id &&
            selectedTask.therapy_id === t.therapy_id,
        )
      ) {
        const tempTask = Object.values(listOfTasks).filter(
          task =>
            task.therapy_id &&
            task.taskType === DC &&
            task.id === t.task_id &&
            task.therapy_id === t.therapy_id,
        );
        if (tempTask && tempTask.length) {
          acc.push(tempTask[0]);
        }
      }
      return acc;
    }, [] as ITask[]);
  };

  const isBeingWorkedAsGroup = (
    dcTask: ITask,
    calculatedWorkAsGroupTasks: { task_id: number; therapy_id: number | null }[],
  ) => {
    /* eslint-disable no-self-compare */
    return !!calculatedWorkAsGroupTasks.filter(
      t => t.task_id === dcTask.id && t.therapy_id === t.therapy_id,
    ).length;
  };

  const handleClick = () => {
    if (!workAsGroup) setWorkAsGroup(true);
    if (excludedTherapyIds.length) setExcludedTherapyIds([]);
    const calculatedWorkAsGroupTasks = calcWorkAsGroupTasks();
    let tasksToMarkAsSelected: any[] = [];
    let wagTasks: ITask[] = [];
    if (calculatedWorkAsGroupTasks && calculatedWorkAsGroupTasks.length) {
      tasksToMarkAsSelected = getWorkAsGroupTasksToBeAdded(
        calculatedWorkAsGroupTasks.reduce((acc, t) => {
          if (t.task_id && t.therapy_id) {
            acc.push({
              task_id: t.task_id,
              therapy_id: t.therapy_id,
            });
          }
          return acc;
        }, [] as { task_id: number; therapy_id: number }[]),
      );

      wagTasks = selectedTasks
        .filter(a => a.taskType === DC)
        .filter(b => isBeingWorkedAsGroup(b, calculatedWorkAsGroupTasks));
    }
    updateWagSelectedTaskkeys(
      wagTasks.length > 1 ? wagTasks : wagTasks.concat(tasksToMarkAsSelected),
    );
    dispatch(
      change(
        formId,
        'work_as_group_tasks',
        wagTasks.length > 1
          ? wagTasks.map(a => {
              return {
                task_id: a.id,
                therapy_id: a.therapy_id,
                service_group_id: a.service_group_id,
              };
            })
          : calculatedWorkAsGroupTasks,
      ),
    );
    dispatch(
      change(
        formId,
        'work_as_group',
        wagTasks.length > 1
          ? wagTasks.map(t => t.therapy_id)
          : calculatedWorkAsGroupTasks.map(t => t.therapy_id),
      ),
    );
    input.onChange(wagTasks.length > 1 ? wagTasks.map(t => t.therapy_id) : icdGroup.therapyIds);
  };

  function onRemoveDCTask(therapyId: number) {
    const listOfExcluded = [...excludedTherapyIds, therapyId];
    setExcludedTherapyIds(excludedTherapyIdsVar => {
      return [...excludedTherapyIdsVar, therapyId];
    });
    const calculatedWorkAsGroupTasks = calcWorkAsGroupTasks().filter(
      t => t.therapy_id && !listOfExcluded.includes(t.therapy_id),
    );
    let wagTasks: ITask[] = [];
    let tasksToMarkAsSelected: any[] = [];
    if (calculatedWorkAsGroupTasks && calculatedWorkAsGroupTasks.length) {
      tasksToMarkAsSelected = getWorkAsGroupTasksToBeAdded(
        calculatedWorkAsGroupTasks.reduce((acc, t) => {
          if (t.task_id && t.therapy_id) {
            acc.push({
              task_id: t.task_id,
              therapy_id: t.therapy_id,
            });
          }
          return acc;
        }, [] as { task_id: number; therapy_id: number }[]),
      );

      wagTasks = selectedTasks
        .filter(a => a.taskType === DC)
        .filter(b => isBeingWorkedAsGroup(b, calculatedWorkAsGroupTasks));
    }
    dispatch(
      excludeSelectedTask(
        selectedWagTaskList.filter(t => t.therapy_id === therapyId).map(getTaskKey),
      ),
    );
    updateWagSelectedTaskkeys(selectedWagTaskList.filter(t => t.therapy_id !== therapyId));
    dispatch(
      change(
        formId,
        'work_as_group_tasks',
        selectedWagTaskList && selectedWagTaskList.length > 1
          ? selectedWagTaskList
              .filter(t => t.therapy_id !== therapyId)
              .map(a => {
                return {
                  task_id: a.id,
                  therapy_id: a.therapy_id,
                  service_group_id: a.service_group_id,
                };
              })
          : undefined,
      ),
    );
    dispatch(
      change(
        formId,
        'work_as_group',
        selectedWagTaskList && selectedWagTaskList.length > 1
          ? selectedWagTaskList.filter(t => t.therapy_id !== therapyId).map(t => t.therapy_id)
          : undefined,
      ),
    );
  }
  return (
    windowFeatureIsEnabled('dc_work_as_a_group') &&
    therapy &&
    icdGroup.therapyIds.length > 1 &&
    icdGroup.therapyIds.some(id => id === therapy.id) &&
    icdGroup.taskIds.length > 1 &&
    (selectedTasks.filter((t: ITask) => t.taskType === INT).length === 0 ||
      selectedTasks
        .filter((t: ITask) => t.taskType === INT)
        .map((th: ITask) => th.therapy_id)
        .every((t: number) => t && !icdGroup.therapyIds.includes(t))) &&
    selectedTasks
      .filter((t: ITask) => t.taskType === DC && t.id && t.therapy_id)
      .map((t: ITask) => {
        return {
          id: t.id,
          therapy_id: t.therapy_id,
        };
      })
      .every(
        dcTaskInfo =>
          Object.keys(
            clinicalData?.find(
              t => t.taskDataCollectId === dcTaskInfo.id && t.therapyId === dcTaskInfo.therapy_id,
            ) ?? {},
          ).length > 0,
      ) && (
      <Box className={classes.root}>
        <Grid container xs={12}>
          <Typography className={classes.text}>
            This data collect is part of a group of therapies for
            <span className={classes.boldtext}>{` "${specialtyTypeName}".`}</span>
          </Typography>
          {!workAsGroup && (
            <Button
              onClick={() => handleClick()}
              color="primary"
              variant="contained"
              disabled={disabled}
            >
              WORK AS GROUP
            </Button>
          )}
          <Grid item xs={12} justifyContent="flex-start">
            {workAsGroup &&
              icdGroup.therapyIds.map(
                therapyId =>
                  therapies[therapyId] &&
                  !excludedTherapyIds.includes(therapyId) &&
                  selectedWagTaskList.map(t => t.therapy_id).includes(therapyId) && (
                    <Chip
                      variant="outlined"
                      key={therapyId}
                      color="primary"
                      // eslint-disable-next-line dot-notation
                      label={therapies[therapyId]['drug_name']}
                      className={classes.chip}
                      onDelete={() => onRemoveDCTask(therapyId)}
                    />
                  ),
              )}
          </Grid>
        </Grid>
      </Box>
    )
  );
};

export default RenderWorkAsGroup;
