import BundleTaskForm from 'components/dynamic-form/bundle-task-form';
import RadioStatusSelector from 'components/radio-status-selector';
import React from 'react';
import TitleHeader from 'components/title-header';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ConditionalWrapper } from 'utils/conditional-wrapper';
import { FC } from 'constants/index';
import { PhoneUsage } from 'interfaces/redux/IPhone';
import { MobilePhoneSmsEnum } from 'constants/enums';
import { FillCoordinationStatus } from 'interfaces/enums/TaskStatuses/FillCoordinationStatus';
import { dynamicFillCoordinationStatusMap } from 'constants/task-statuses';
import { IFillCoordinationTask } from 'interfaces/redux/task-types/IFillCoordinationTask';
import { IStatusSelectorOption } from 'interfaces/IStatusSelectorOption';
import { StatusMapUtil } from 'utils/status-map-util';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { TaskStatus } from 'interfaces/enums/TaskStatus';
import { styles } from './fill-coordination-transition.styles';
import { IProps } from './interfaces/IProps';
import Complete from './forms/complete';
import ResetToRequiredStateForm from './forms/reset-fc-to-required-state-form';
import { selectIsDurRequired } from '../fc-util';

const FC_DUR_ERROR_MESSAGE = 'Pharmacist Review on DUR is required to mark FC as completed';

const FillCoordinationTransition: React.FC<IProps> = (props: IProps): JSX.Element | null => {
  // #region Redux stuff
  const currentUser = useTypedSelector(state => state.auth?.currentUser);
  const lookups = useTypedSelector(state => state.lookups || []);
  const secureFCLinkSender = useTypedSelector(state =>
    props.tasks[0].secure_link_sent_by_user_id
      ? state.lookups.users.find(user => user.id === props.tasks[0].secure_link_sent_by_user_id)
          ?.display_name
      : '',
  );
  const patient = useTypedSelector(state => state.patient);
  const smsPhoneNumber = (patient.phones || []).find(
    phone =>
      phone.use === PhoneUsage.Mobile &&
      phone.patient_choice === true &&
      phone.sms === MobilePhoneSmsEnum.OptIn,
  )?.value;
  const FillCoordinationStatusMap = dynamicFillCoordinationStatusMap();
  const fcIdsInBundle = (props.tasks[0].fc_ids_in_bundle || []).slice().sort();
  const fcIdsSelected = props.tasks.map(task => task.id);
  const allFCsInBundleSelected = fcIdsInBundle.reduce(
    (acc, _item, index) =>
      acc &&
      fcIdsInBundle.length === fcIdsSelected.length &&
      fcIdsSelected[index] === fcIdsInBundle[index],
    true,
  );
  const therapyIdsToRemove = (props.tasks[0].therapy_ids_to_remove || []).slice().sort();
  const therapyIdsInBundle = props.tasks
    .map((task: any) => task.therapy_id)
    .slice()
    .sort();
  const emptyOrder =
    allFCsInBundleSelected &&
    therapyIdsInBundle.reduce(
      (acc, _item, index) =>
        acc &&
        therapyIdsInBundle.length === therapyIdsToRemove.length &&
        therapyIdsInBundle[index] === therapyIdsToRemove[index],
      true,
    );
  const resourceStates = (lookups?.resourceStates || []).filter(
    resourceState => resourceState.resource_name === FC,
  );
  // #endregion Redux stuff

  // #region component state
  const [hideTitle, setHideTitle] = React.useState(props.hideTitle);
  const [wrapWithCard, setWrapWithCard] = React.useState<boolean>(true);
  const [hideStatusSelector, setHideStatusSelector] = React.useState(props.hideStatusSelector);
  const [statusOptions, setStatusOptions] = React.useState<IStatusSelectorOption[]>([]);
  const [currentStatusName, setCurrentStatusName] = React.useState<string | undefined>(undefined);
  const [currentSelectedStatusId, setCurrentSelectedStatusId] = React.useState<number>(() => {
    if (emptyOrder) {
      return 4001;
    }
    const startingStatus = FillCoordinationStatusMap[props.tasks[0].status_id];
    const nextStatusId = props.newStatusId ?? startingStatus.nextStatus ?? props.tasks[0].status_id;
    return nextStatusId ?? -1;
  });
  const [startingStatusId, setStartingStatusId] = React.useState(props.tasks[0].status_id);
  const [canUpdateStatus, setCanUpdateStatus] = React.useState<boolean>(true);
  const last_status_id = React.useRef(props.tasks[0].status_id);
  const [completeKey, setCompleteKey] = React.useState(1);
  // #endregion component state

  React.useEffect(() => {
    const new_status_id = props.tasks[0].status_id;
    if (last_status_id.current !== new_status_id) {
      last_status_id.current = new_status_id;
      setCompleteKey(completeKey + 1);
    }
  }, [props.tasks]);

  // #region useEffects
  React.useEffect(() => {
    const startingStatus = FillCoordinationStatusMap[props.tasks[0].status_id];
    setStartingStatusId(props.tasks[0].status_id);
    setCurrentStatusName(startingStatus.status.toString());
    if (props.tasks[0].status_id !== 4009) {
      setHideStatusSelector(false);
    } else {
      setHideStatusSelector(true);
    }
  }, [props.tasks[0].status_id]);

  React.useEffect(() => {
    const startingStatus = FillCoordinationStatusMap[props.tasks[0].status_id];
    if (emptyOrder) {
      setCurrentSelectedStatusId(4001);
      setStatusOptions([
        {
          hidden: false,
          label: 'Required',
          onClick: () => {},
          selected: true,
          sortIndex: 0,
          value: 4001,
        },
      ]);
      return;
    }
    if (startingStatus) {
      const nextStatusId =
        props.newStatusId ?? startingStatus.nextStatus ?? props.tasks[0].status_id;
      const mappedStatuses = StatusMapUtil.convertMapToStatusOptions(
        FillCoordinationStatusMap,
        nextStatusId,
        nextStatusId,
        handleStatusChange,
      );
      setStatusOptions(sortMappedStatuses(mappedStatuses));
      setCurrentStatusName(startingStatus.status.toString());
      if (nextStatusId) {
        updateStateBasedOnSelectedStatus(nextStatusId);
        setCurrentSelectedStatusId(nextStatusId);
      }
    } else {
      setCurrentStatusName(undefined);
    }
  }, [props.newStatusId, props.tasks[0].status_id]);

  React.useEffect(() => {
    if (!currentSelectedStatusId) {
      const startingStatus = FillCoordinationStatusMap[props.tasks[0].status_id];
      if (startingStatus) {
        const nextStatusId =
          props.newStatusId ?? startingStatus.nextStatus ?? props.tasks[0].status_id;
        if (nextStatusId) {
          setCurrentSelectedStatusId(nextStatusId);
        }
      }
    } else {
      const statusId = props.newStatusId || currentSelectedStatusId;
      updateStateBasedOnSelectedStatus(statusId);
    }
  }, [props.tasks]);
  // #endregion useEffects

  // #region helper funcs
  const sortMappedStatuses = (statuses: IStatusSelectorOption[]): IStatusSelectorOption[] => {
    const sorted = statuses.sort((a, b) => a.sortIndex - b.sortIndex);

    return sorted;
  };

  const handleStatusChange = (statusId: number) => {
    const startingStatus = FillCoordinationStatusMap[props.tasks[0].status_id];
    const mappedStatuses = StatusMapUtil.convertMapToStatusOptions(
      FillCoordinationStatusMap,
      startingStatus.nextStatus ?? props.tasks[0].status_id,
      statusId,
      handleStatusChange,
    );
    setStatusOptions(sortMappedStatuses(mappedStatuses));
    const selectedStatus = FillCoordinationStatusMap[statusId];
    if (selectedStatus) {
      updateStateBasedOnSelectedStatus(statusId);
      setCurrentSelectedStatusId(statusId);
    }
  };

  // TODO: Remove unused variables
  const updateStateBasedOnSelectedStatus = (selectedStatusId: number): void => {
    /**
     * When the form is transitioning to the completed status and not already complete
     */
    const tasksAreComplete = props.tasks.every(
      t => t.status_id === FillCoordinationStatus.Completed,
    );

    const taskIsWaitingOnPatientResponse = props.tasks.every(
      t => t.status_id === FillCoordinationStatus.In_Progress_Waiting_for_Patient_Response,
    );

    if (selectedStatusId === FillCoordinationStatus.Completed && !tasksAreComplete) {
      setCanUpdateStatus(true);
    }

    if (taskIsWaitingOnPatientResponse) {
      setHideStatusSelector(true);
    }
    // If the task is complete, don't show the status selector or current status name
    if (tasksAreComplete) {
      setHideStatusSelector(true);
      setHideTitle(true);
      setWrapWithCard(false);
    }

    setCanUpdateStatus(true);
  };

  const getTitle = (): string => {
    const baseText = 'Fill Coordination';
    let addlText = '';
    if (props.tasks.length === 1) {
      addlText = ` #${props.tasks[0].fill_cycle_number}`;
    }

    return `${baseText}${addlText}`;
  };
  const durRequired = useTypedSelector(selectIsDurRequired);
  // #endregion helper funcs

  // #region render helpers
  const renderNewForm = (): JSX.Element => {
    const showUploadFile =
      currentSelectedStatusId !== 4009 && startingStatusId !== 4009 && startingStatusId !== 4012;
    return (
      <Grid container>
        <Complete
          key={completeKey}
          tasks={props.tasks}
          form="FILL_COORDINATION_TRANSITION"
          selectedStatusId={currentSelectedStatusId}
          onCancel={props.legacyCancelClick}
          onSelectTaskIds={props.onSelectTaskIds}
          showUploadFile={showUploadFile}
          changeSelectedStatus={handleStatusChange}
          handleOnSelectedRow={props.handleOnSelectedRow}
        />
      </Grid>
    );
  };

  const renderLegacyForm = (): JSX.Element => {
    /** When the form is not transitioning to the completed status (any other status) */
    const statusIds: Record<number, number> = {};
    const taskIds: number[] = [];
    const tasksWithStatus: Record<string, IFillCoordinationTask[]> = { [FC]: [] };

    props.tasks.forEach(task => {
      tasksWithStatus[FC].push({ ...task, status_id: currentSelectedStatusId });
      statusIds[task.id] = currentSelectedStatusId;
      taskIds.push(task.id);
    });

    return (
      <BundleTaskForm
        {...props.legacyFormProps}
        combinedTask={tasksWithStatus}
        taskIds={taskIds}
        hideCard
        hidePatientLevelTitle
        currentTaskStatusIds={statusIds}
      />
    );
  };
  // #endregion

  const displayNewForm =
    (currentSelectedStatusId === FillCoordinationStatus.Completed ||
      currentSelectedStatusId === FillCoordinationStatus.In_Progress_Call_Patient ||
      currentSelectedStatusId === FillCoordinationStatus.In_Progress_Patient_Response_Ready ||
      currentSelectedStatusId === FillCoordinationStatus.In_Progress_Waiting_for_Patient_Response ||
      currentSelectedStatusId === FillCoordinationStatus.In_Progress) &&
    props.tasks[0].status_id !== FillCoordinationStatus.Completed;

  const displayResetToRequiredForm = currentSelectedStatusId === FillCoordinationStatus.Required;

  const selectForm = () => {
    if (displayResetToRequiredForm) {
      return (
        <ResetToRequiredStateForm
          tasks={props.tasks}
          patientId={patient.id}
          smsPhoneNumber={smsPhoneNumber}
          emptyOrder={emptyOrder}
          secureFCLinkSender={secureFCLinkSender}
        />
      );
    }
    return displayNewForm ? renderNewForm() : renderLegacyForm();
  };

  // this is a safety check to prevent an initial rendering of one form
  // followed almost immediately by the rendering of the other form.
  // This is only triggered if for some reason the initial value for currentSelectedStatusId
  // cannot be determined it will set it to -1 so nothing is rendered until the useEffect runs.
  if (currentSelectedStatusId === -1) {
    return null;
  }

  // #region render
  return (
    <ConditionalWrapper
      condition={wrapWithCard}
      wrapper={child => (
        <div style={{ width: '100%' }} data-qa-id="card-content">
          {child}
        </div>
      )}
    >
      {!hideTitle && (
        <TitleHeader
          title={getTitle()}
          currentStatusName={currentStatusName}
          isCounseling={false}
        />
      )}

      {!hideStatusSelector && (
        <RadioStatusSelector
          displayInline
          label="Next status *"
          options={statusOptions}
          disableMoreOption={!canUpdateStatus}
          disableStatuses={durRequired ? [TaskStatus.Completed] : undefined}
          errorMessage={durRequired ? FC_DUR_ERROR_MESSAGE : undefined}
          task={props.tasks?.[0]}
          currentUser={currentUser}
          resourceStates={resourceStates}
        />
      )}
      {selectForm()}
    </ConditionalWrapper>
  );
  // #endregion render
};

export default withStyles(styles)(FillCoordinationTransition);
