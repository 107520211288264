import { ISocket } from 'interfaces/redux/ISocket';
import { Reducer } from 'redux';
import { io } from 'socket.io-client';
import { windowFeatureIsEnabled } from '../config/window-features';
import {
  FaGrantNotificationActionPayload,
  IAction,
  SocketActionsEnum,
} from '../actions/action-socket';

const BASE_URL_SOCKET = 'wss://socketio.arborpoc.trellisrx.com';
const defaultConfig = { transports: ['websocket'], withCredentials: true };
const isRefillPage = window.location.pathname.substring(0, 9) === '/refills/';

const initialState: ISocket = {
  faSocket: null,
  fcSocket: null,
  rulesEngineSocket: null,
  lastNotification: null,
  notifications: [],
};
export const socket: Reducer<ISocket, IAction> = (state = initialState, { type, payload }) => {
  if (isRefillPage) {
    return { ...state };
  }
  const faLinkingFeature = windowFeatureIsEnabled('fa_linking');
  const fcSelfServiceFeature = windowFeatureIsEnabled('fc_self_service');

  if (type === SocketActionsEnum.FA_GRANT_NOTIFICATION) {
    const faPayload = payload as FaGrantNotificationActionPayload;
    const notificationExists = state.notifications.some(
      notification => notification.id === faPayload.notification.id,
    );
    return {
      ...state,
      faSocket: faPayload.socket,
      lastNotification: faPayload.notification,
      notifications: !notificationExists
        ? [...state.notifications, faPayload.notification]
        : [...state.notifications],
    };
  }

  return {
    ...state,
    faSocket:
      !state.faSocket && faLinkingFeature
        ? io(`${BASE_URL_SOCKET}/fa`, defaultConfig)
        : state.faSocket,
    fcSocket:
      !state.fcSocket && fcSelfServiceFeature
        ? io(`${BASE_URL_SOCKET}/fc`, defaultConfig)
        : state.fcSocket,
    rulesEngineSocket: !state.rulesEngineSocket
      ? io(`${BASE_URL_SOCKET}/rules-engine`, defaultConfig)
      : state.rulesEngineSocket,
  };
};
