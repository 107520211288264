/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/function-component-definition */
import _ from 'lodash';
import ConfirmationDialogPanel from 'components/form/confirmation/confirmation-dialog-panel';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import FileSelectedForm from 'components/document/file-forms/file-selected-form';
import moment from 'moment';
import PendingDocument from 'components/document/pending-document';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import SelectFileForm from 'components/document/file-forms/select-file-form';
import { AxiosPromise } from 'axios';
import { BundleTaskUtil } from 'services/utils/bundle-task-util';
import { Grid, Typography, Backdrop, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import { editTasks, updateFCDispensingPharmacyInRedux } from 'actions/action-tasks';
import { FDC, PENDING_DOCUMENT_LOSS } from 'constants/index';
import { FillCoordinationStatusMap } from 'constants/task-statuses';
import { formValueSelector, getFormValues, InjectedFormProps } from 'redux-form';
import { IFillCoordinationTask } from 'interfaces/redux/task-types/IFillCoordinationTask';
import { IState } from 'interfaces/redux/IState';
import { MethodOptions } from 'constants/method-options';
import { nameOfFactory } from 'utils/types-util';
import { NoteTagLabels } from 'constants/note-tag-labels';
import { NoteTagTypes } from 'constants/note-tag-types';
import { notifyError } from 'actions/action-notifications';
import { updatePatientPreferences } from 'actions/action-patient';
import { updateTherapiesDispensingPharmacyInRedux } from 'actions/action-therapies';
import { TaskCategory } from 'interfaces/enums/TaskCategory';
import { uploadFile } from 'services/utils/upload-document-service';
import {
  ICompleteFillCoordinationBundleTaskPayload,
  IDocument,
} from 'interfaces/fill-coordination/bundle-types';
import { globalZIndex } from 'constants/z-index';
import {
  acceptedFileExtensions,
  fileTypeError,
  fileTypeErrorCaption,
  getFileExtension,
} from 'services/utils/document-service';
import { ReduxFormUtil } from 'utils/redux-form-util';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { FillCoordinationStatus } from 'interfaces/enums/TaskStatuses/FillCoordinationStatus';
import { DraftFillCoordinationClient } from 'clients/draft-fill-coordination-client';
import {
  IDraftFillCoordinationDetails,
  IExistingIntervention,
} from 'models/tasks/DraftFillCoordinationDetails';
import { printPdfDocument } from 'containers/patient/documents/preview-document';
import { windowFeatureIsEnabled } from 'config/window-features';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { IAddress } from 'components/document/generator/interfaces/fc-types';
import { DeliveryMethodValue, FCStatus } from 'constants/enums';
import { selectTherapiesList } from 'containers/patient/med-sync/med-sync-state';
import { createSelector } from '@reduxjs/toolkit';
import createMemoReduxForm from 'utils/create-memo-redux-form';
import MissingResponses from '../../../common/missing-required-form-values/missing-required-form-values';
import { logger } from '../../../../../winston-logger';
import {
  buildInitialValues,
  otcProvider,
  updateWeatherBasedOnAddress,
  buildFullBundlePayload,
  calculateTotalCopayAmount,
  buildPerTaskInfo,
  buildPreferencesByAncillarySupplies,
  calculateNextNeedsByDate,
  nameOfFormFieldsPerTask,
  calculateNeedsByDate,
  calculateOnHandQty,
  coldChainDisabled,
  selectIsDurRequired,
  selectMissingRequiredKeys,
} from '../../fc-util';
import { LineDivider, styles } from '../../../task-detail-styles';
import { IProps } from './interfaces/IProps';
import { IFillCoordinationEditFormFields, IShortIntervention } from '../../types';
import { FillCoordinationEditReduxForm } from '../../fill-coordination-edit-form';

const isTherapyInMedSync = (therapy?: ITherapy) =>
  windowFeatureIsEnabled('med_sync') && therapy?.in_med_sync;

const nameOfFormFields = nameOfFactory<IFillCoordinationEditFormFields>();
const fieldNamePerTask = (
  field: keyof IFillCoordinationEditFormFields,
  task: IFillCoordinationTask,
) => ReduxFormUtil.getFieldNamePerTask<IFillCoordinationEditFormFields>(field, task);

interface IStateProps {
  internalPharmacies: any;
  initialValues: Partial<IFillCoordinationEditFormFields>;
  currentValues: Partial<IFillCoordinationEditFormFields>;
  isDurRequiredForFc: boolean;
  hasMissingKeys: boolean;
}
type Props = IStateProps &
  IProps &
  InjectedFormProps<IFillCoordinationEditFormFields> & {
    updatePatientPreferences: any;
    updateFCDispensingPharmacyInRedux: any;
    updateTherapiesDispensingPharmacyInRedux: any;
    handleOnSelectedRow: Function;
  };

const selectActiveTherapies = createSelector([selectTherapiesList], therapies =>
  therapies.filter(
    therapy =>
      (therapy.category_id === 1 || therapy.category_id === 2) &&
      therapy.dispensing_status === 'Opt in',
  ),
);

const Complete: React.FC<Props> = (props: Props): JSX.Element => {
  // #region redux
  const dispatch = useDispatch();
  const [removedFCIds, setRemovedFCIds] = useState<number[]>([]);
  const state = useTypedSelector(state => state);
  const patient = useTypedSelector(state => state.patient);
  const therapies = useTypedSelector(state => state.therapies.data);
  const contactList = useTypedSelector(state => state.contactList);
  const statuses = useTypedSelector(state => state.taskStatuses.statuses.fc);
  const userData = useTypedSelector(state => state.lookups.users);
  const selectedCustomerId = useTypedSelector(state => state.filters.selectedCustomerId);
  const notes = useTypedSelector(state => state.notes);
  const documents = useTypedSelector(state => state.uploadedDocuments);
  const defaultDocumentLabels = useTypedSelector(state => state.lookups.defaultDocumentLabels);
  const fillCycles = useTypedSelector(state => state.fillCycles);
  const allTherapies = Object.values(useTypedSelector(state => state.therapies.data));
  const activeTherapies = useTypedSelector(selectActiveTherapies);
  const inprogressFCTasks = useTypedSelector(state =>
    state?.tasks?.data
      ? Object.values(state?.tasks?.data).filter(
          task => task.taskType === 'FC' && task.status_id === 4007,
        )
      : [],
  );
  const formValues = useTypedSelector(state => state.form[props.form]);
  // #endregion redux

  // #region component state

  // eslint-disable-next-line no-spaced-func
  const [lostDataDialogSettings, setLostDataDialogSettings] = React.useState<{
    messages?: string[];
    // eslint-disable-next-line func-call-spacing
    dialogCancel?: () => void;
    dialogContinue?: () => void;
  }>();
  const [showSendSecureLinkWarning, setShowSendSecureLinkWarning] = React.useState<{
    show: boolean;
    showCancelButton?: boolean;
    // eslint-disable-next-line func-call-spacing
    dialogCancel?: () => void;
    dialogContinue?: () => void;
  }>({ show: false });
  // eslint-disable-next-line no-spaced-func
  const [shippingAddressDialogSettings, setShippingAddressDialogSettings] = React.useState<{
    continueText?: string;
    showCancelButton?: boolean;
    messages?: string;
    // eslint-disable-next-line func-call-spacing
    dialogCancel?: () => void;
    dialogContinue?: () => void;
  }>();
  const [patientChoicesViolation, setPatientChoicesViolation] = React.useState<{
    show: boolean;
    continueText?: string;
    showCancelButton?: boolean;
    therapies?: string[];
    dialogCancel?: Function;
    dialogContinue?: Function;
  }>({ show: false });
  // TODO: ARBOR-11486 - does this need to be separate from store data?
  const [selectedTaskIds, setSelectedTaskIds] = React.useState<Set<number>>(new Set());
  const [pendingDocumentLoss, setPendingDocumentLoss] = React.useState<boolean>(false);
  const [uploadErrorMessage] = React.useState<string | undefined>(undefined);
  const [uploadProcessing, setUploadProcessing] = React.useState<boolean>(false);
  const [showTherapiesNdcsWarning, setShowTherapiesNdcsWarning] = React.useState<boolean>(false);
  const [addressValue, setAddressValue] = React.useState<string | undefined>(undefined);
  const [shipDate, setShipDate] = React.useState<moment.Moment | undefined>(undefined);
  const [fileErrors, setFileErrors] = React.useState<{
    fileErrorMessage?: string;
    fileErrorCaption?: string;
  }>({});

  const statusIsWaitingPatientResponse =
    props.selectedStatusId === FCStatus.in_progress_waiting_for_patient_response ||
    props.tasks[0].status_id === FCStatus.in_progress_waiting_for_patient_response;

  const [pendingDocument, setPendingDocument] = React.useState<
    { fileName: string; fileExtension: string; file: any } | undefined
  >(undefined);
  const [uploadedDocuments, setUploadedDocuments] = React.useState<IDocument[]>([]);
  const initial_interventions =
    props.tasks[0].status_id === FCStatus.in_progress_call_patient &&
    props.tasks[0].patient_missed_doses
      ? [
          {
            status_id: 8001,
            reaction: null,
            suspected_caused_by: null,
            category_id: 1,
            type_id: 1,
            details: '',
            sideeffect_type: 2,
          },
        ]
      : [];
  if (
    props.tasks[0].status_id === FCStatus.in_progress_call_patient &&
    props.tasks[0].side_effects
  ) {
    initial_interventions.push({
      status_id: 8001,
      reaction: null,
      suspected_caused_by: null,
      category_id: 1,
      type_id: 4,
      details: '',
      sideeffect_type: 1,
    });
  }
  const [newInterventions, setNewInterventions] =
    React.useState<IShortIntervention[]>(initial_interventions);
  const [existingInterventions, setExistingInterventions] = React.useState<{
    [fcId: number]: IExistingIntervention[];
  }>({});
  const [groupedFillCoordinationIds, setGroupedFillCoordinationIds] = React.useState<{
    [fcId: number]: number[];
  }>({});
  const [shippingAddressIsNotPatientPreferred, setShippingAddressIsNotPatientPreferred] =
    React.useState<boolean>(false);

  const copayAmountList = Object.values(
    (props.currentValues?.copay_amount as unknown as Record<number, string | null>) || {},
  );
  const currentDeliveryMethod = props.currentValues.preferred_rx_delivery_method?.value;
  // #endregion

  // #region helper functions
  const handleCancel = useCallback(() => {
    props.onCancel(props.tasks.map(x => x.id));
  }, [props.onCancel]);

  const fileUploadFormName = (): string => {
    return `${props.form}_FILE_UPLOAD_FORM`;
  };

  // TODO: change this event to not "any"
  const filePaste = (event: any) => {
    if (event && event.clipboardData) {
      const { files } = event.clipboardData;
      if (files.length) {
        fileAccepted(files);
      } else {
        fileRejected();
      }
    }
  };

  // TODO: change this event to not "any"
  const fileAccepted = (event: any[]) => {
    if (event && event.length) {
      setFileErrors({});
    }

    const { filePrefix, fileExtension } = getFileExtension(event[0].name);
    if (acceptedFileExtensions.includes(fileExtension.toLowerCase())) {
      const selectedFile = event[0];
      setPendingDocument({
        fileName: filePrefix,
        fileExtension: fileExtension,
        file: selectedFile,
      });
    }
  };

  const fileRejected = () => {
    setFileErrors({
      fileErrorMessage: fileTypeError,
      fileErrorCaption: fileTypeErrorCaption,
    });
  };

  const fileUploadCancel = () => {
    setPendingDocument(undefined);
  };

  const fileUploadSubmit = () => {
    if (pendingDocument) {
      setUploadProcessing(true);

      const fullFileName = `${pendingDocument.fileName}.${pendingDocument.fileExtension}`;
      const formValues = getFormValues(fileUploadFormName())(
        state,
      ) as Partial<IFillCoordinationEditFormFields>;

      uploadFile(pendingDocument.file, fullFileName, selectedCustomerId, patient.id).then(
        result => {
          setUploadedDocuments(prevState => {
            const newState = [...prevState];
            newState.push({
              uuid: result.uuid,
              file_name: fullFileName,
              labels: formValues.UPLOAD_DOC_FILETYPE_FIELD?.split(',') || [],
              tags: props.tasks.map(x => ({ tag_type_id: NoteTagTypes.FC, resource_id: x.id })),
              noteText: formValues.UPLOAD_DOC_FILENOTE_FIELD,
            });
            return newState;
          });
          setPendingDocument(undefined);
          setUploadProcessing(false);
        },
      );
    }
  };

  const generateAndSubmitPayload = async (
    formValues: Partial<IFillCoordinationEditFormFields>,
  ): Promise<any> => {
    let payload: ICompleteFillCoordinationBundleTaskPayload | undefined;
    try {
      payload = buildFullBundlePayload(
        props.selectedStatusId,
        formValues,
        props.tasks,
        patient,
        uploadedDocuments,
        newInterventions,
        contactList,
        removedFCIds,
      );
    } catch (error) {
      await dispatch(notifyError(error));
      return null;
    }

    if (payload) {
      const sync_disp_pharm_payload: Record<string, any> = {};
      if (formValues.apply_choice_to_therapies_and_FCs) {
        const newPharmacy = props.internalPharmacies.find(
          (pharm: any) => pharm.npi === formValues.dispensing_pharmacy?.dispensing_pharmacy_npi,
        );
        sync_disp_pharm_payload.sync_therapies_and_fcs_disp_pharm_payload = true;
        sync_disp_pharm_payload.pharmacy = newPharmacy;
        sync_disp_pharm_payload.therapyIds = activeTherapies.map(therapy => therapy.id);
        sync_disp_pharm_payload.fcIds = inprogressFCTasks.map(task => task.id);
        const updatedTherapiesPayload = {
          therapyIds: activeTherapies.map(therapy => therapy.id),
          pharmacy: newPharmacy,
        };
        props.updateTherapiesDispensingPharmacyInRedux(updatedTherapiesPayload);
        const updatedFCPayload = {
          fcIds: inprogressFCTasks.map(task => task.id),
          pharmacy: newPharmacy,
        };
        props.updateFCDispensingPharmacyInRedux(updatedFCPayload);
      }
      let removedFCDetails;
      if (removedFCIds.length) {
        const removedFCs = removedFCIds.map(id => props.tasks.find(task => task.id === id));
        const removedTherapyInfoByFCId: Record<number, any> = {};
        removedFCs.forEach((task: any) => {
          const therapy = therapies[task.therapy_id];
          const therapyInfo = `${therapy.drug_name} ${therapy.dosage_form} ${therapy.strength} ${therapy.strength_unit_of_measure}`;
          removedTherapyInfoByFCId[task.id as number] = therapyInfo;
        });
        removedFCDetails = {
          removed_fc_ids: removedFCIds,
          removed_therapies_info: removedTherapyInfoByFCId,
        };
      }
      // Dispatch the API request and update the rest of the state
      try {
        const result = await dispatch(
          editTasks(
            patient.id,
            payload.tasks,
            payload.orders,
            payload.notes,
            payload.documents,
            payload.new_interventions,
            sync_disp_pharm_payload,
            removedFCDetails,
          ) as unknown as AxiosPromise<any>,
        );
        if (result) {
          BundleTaskUtil.handleBundleTaskCompletion(
            dispatch,
            payload!.notes,
            notes,
            patient.id,
            documents,
            payload?.documents,
          );
          return result;
        }
      } catch (err) {
        logger.error('Update FC task error', err);
        dispatch(notifyError((err as Error).message ?? 'Unable to edit tasks'));
      }
    }
    return null;
  };

  /**
   * Submits the form after `handleSubmit` has checked if the user might lose any data
   */
  const finalizeSubmit = async (
    formValues: Partial<IFillCoordinationEditFormFields>,
  ): Promise<void> => {
    if (formValues.status_id) {
      const matchingStatus = FillCoordinationStatusMap[props.selectedStatusId];
      const isComplete = matchingStatus?.category === TaskCategory.Done;
      if (isComplete) {
        const medicationPackagingPreferences = buildPreferencesByAncillarySupplies(
          patient,
          formValues?.ancillary_supplies,
        );
        Object.assign(medicationPackagingPreferences, {
          adherence_packaging:
            medicationPackagingPreferences.adherence_packaging === true || undefined,
        });

        const updatedPatientPreferences: any = {
          patient_id: patient.id,
          ...medicationPackagingPreferences,
        };

        if (formValues.apply_choice_to_therapies_and_FCs) {
          const newPharmacyId = props.internalPharmacies.find(
            (pharm: any) => pharm.npi === formValues.dispensing_pharmacy?.dispensing_pharmacy_npi,
          )?.id;
          updatedPatientPreferences.preferred_rx_delivery_pharmacy_id = newPharmacyId;
        }

        // Update patient preferences only if there is something to update
        if (
          Object.keys(medicationPackagingPreferences).length ||
          updatedPatientPreferences.preferred_rx_delivery_pharmacy_id
        ) {
          props.updatePatientPreferences(updatedPatientPreferences, patient);
        }
      } else if (formValues.apply_choice_to_therapies_and_FCs) {
        const newPharmacyId = props.internalPharmacies.find((pharm: any) => {
          return pharm.npi === formValues.dispensing_pharmacy?.dispensing_pharmacy_npi;
        })?.id;
        const updatePayload = {
          patient_id: patient.id,
          preferred_rx_delivery_pharmacy_id: newPharmacyId,
        };
        props.updatePatientPreferences(updatePayload, patient);
      }

      const response = await generateAndSubmitPayload(formValues);
      const updatedTasks: { taskType: string; orderFormDocumentId: number }[] =
        response?.payload?.data?.updated_tasks;
      if (!windowFeatureIsEnabled('fdc_statuses')) {
        const createdFDC = updatedTasks?.find(task => task.taskType === FDC);
        const orderFormDocumentId = createdFDC?.orderFormDocumentId;
        if (orderFormDocumentId != null) {
          await printPdfDocument(orderFormDocumentId, selectedCustomerId, patient.id);
        }
      }
      if (updatedTasks) {
        // Only cancel if updated tasks are successful
        handleCancel();
      }
    }
  };

  const shippingAddressValidation = (
    shippingAddress: { value: IAddress | number; label: string } | undefined,
  ): boolean => {
    if (!shippingAddress || !patient) {
      return false;
    }

    if (patient.preferred_rx_delivery_patient) {
      const referenceAddress =
        patient.addresses && patient.addresses.length
          ? patient.addresses.find(item => item.key === patient.preferred_rx_delivery_entity_key)
          : null;
      return _.isEqual(shippingAddress.value, referenceAddress);
    }

    if (patient.preferred_rx_delivery_contact_id) {
      const contact = contactList[patient.preferred_rx_delivery_contact_id];
      const referenceAddress = contact?.addresses?.find(
        item => item.key === patient.preferred_rx_delivery_entity_key,
      );
      return _.isEqual(shippingAddress.value, referenceAddress);
    }
    return false;
  };

  const checkForPendingDocumentLoss = async () => {
    return new Promise((fn_continue, fn_cancel) => {
      if (pendingDocument) {
        setPendingDocumentLoss(true);
        setLostDataDialogSettings(prevState => {
          return {
            ...prevState,
            messages: [`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`],
            dialogCancel: () => {
              setPendingDocumentLoss(false);
              fn_cancel(new Error('cancel'));
            },
            dialogContinue: async () => {
              setPendingDocumentLoss(false);
              fn_continue(true);
            },
          };
        });
      } else {
        fn_continue(true);
      }
    });
  };

  const checkForComplianceWithPatientChoices = async () => {
    return new Promise((fn_continue, fn_cancel) => {
      const therapyIdsStillInBundle = props.tasks
        .filter(task => !removedFCIds.includes(task.id))
        .map(task => task.therapy_id);
      const therapiesById: Record<number, any> = {};
      Object.values(therapies).map(therapy => (therapiesById[therapy.id] = therapy));
      const therapiesToRemove = (props.tasks[0].therapy_ids_to_remove || [])
        .filter(therapyId => therapyIdsStillInBundle.includes(therapyId))
        .map(therapyId => therapiesById[therapyId]);
      const therapiestoRemoveAsStrings = therapiesToRemove.map(
        therapy =>
          `  ${therapy.drug_name} ${therapy.dosage_form} ${therapy.strength} ${therapy.strength_unit_of_measure}`,
      );
      if (therapiesToRemove.length) {
        setPatientChoicesViolation(() => {
          return {
            show: true,
            therapies: therapiestoRemoveAsStrings,
            dialogCancel: () => {
              setPatientChoicesViolation({ show: false });
              fn_cancel(new Error('cancel'));
            },
            dialogContinue: async () => {
              setPatientChoicesViolation({ show: false });
              fn_continue(true);
            },
          };
        });
      } else {
        fn_continue(true);
      }
    });
  };

  const checkShippingAddress = async () => {
    return new Promise((fn_continue, fn_cancel) => {
      const formValues = props.currentValues;
      const matchingStatus = FillCoordinationStatusMap[props.selectedStatusId];
      const isTransitioningToComplete = matchingStatus?.category === TaskCategory.Done;
      const shouldCheckDeliveryAddress =
        currentDeliveryMethod !== DeliveryMethodValue.PickUp &&
        isTransitioningToComplete &&
        Object.keys(contactList);
      if (shouldCheckDeliveryAddress) {
        const isShippingAddressPatientPreferred = shippingAddressValidation(formValues?.address);
        if (!isShippingAddressPatientPreferred) {
          setShippingAddressIsNotPatientPreferred(true);
          setShippingAddressDialogSettings(() => {
            return {
              continueText: 'Continue with current selection',
              messages:
                "The selected delivery address is different from the patient's preferred Rx delivery address.",
              dialogCancel: () => {
                setShippingAddressIsNotPatientPreferred(false);
                fn_cancel();
              },
              dialogContinue: async () => {
                setShippingAddressIsNotPatientPreferred(false);
                fn_continue(true);
              },
            };
          });
          return;
        }
      }
      fn_continue(true);
    });
  };

  const submitWithPrechecks = async (e: any) => {
    checkShippingAddress()
      .then(() => checkForComplianceWithPatientChoices())
      .then(() => checkForPendingDocumentLoss())
      .then(() => props.handleSubmit(finalizeSubmit)(e))
      .catch(() => {});
  };

  const changeNewInterventionsHandler = useCallback(
    (newInterventionsUpdated: IShortIntervention[]) => {
      setNewInterventions(newInterventionsUpdated);
    },
    [],
  );

  const changeAncillarySupplies = useCallback((value: string) => {
    props.change(nameOfFormFields('ancillary_supplies'), value);
  }, []);

  const handleExpectedDeliveryDateChange = useCallback(
    (deliveryDate: Date) => {
      setShowTherapiesNdcsWarning(false);
      if (
        activeTherapies
          .filter(({ needsby_date: needsByDate }) => moment(needsByDate).isAfter())
          .some(
            ({ needsby_date: needsByDate }) =>
              moment(deliveryDate).diff(moment(needsByDate), 'days') > 0,
          )
      ) {
        setShowTherapiesNdcsWarning(true);
      }
    },
    [activeTherapies],
  );

  // #endregion helper functions
  React.useEffect(() => {
    const updateAddedTask = async (task: IFillCoordinationTask) => {
      let draftFC: IDraftFillCoordinationDetails | undefined;
      // let draftFC: any;
      try {
        if (task.status_id === 4007) {
          const response = await DraftFillCoordinationClient.fetch(task.id);
          draftFC = response.data || undefined;
        }
      } catch (error) {
        logger.error(error);
        dispatch(notifyError('Unable to fetch draft fill coordination details.'));
      }
      const existingInts = draftFC?.interventions ?? [];
      const newInts = [];
      if (
        task.status_id === FCStatus.in_progress &&
        !existingInts.some(int => int.typeId === 1) &&
        task.patient_missed_doses
      ) {
        newInts.push({
          status_id: 8001,
          reaction: null,
          suspected_caused_by: null,
          category_id: 1,
          type_id: 1,
          details: '',
          sideeffect_type: 2,
        });
      }
      if (
        task.status_id === FCStatus.in_progress &&
        !existingInts.some(int => int.typeId === 4) &&
        task.side_effects
      ) {
        newInts.push({
          status_id: 8001,
          reaction: null,
          suspected_caused_by: null,
          category_id: 1,
          type_id: 4,
          details: '',
          sideeffect_type: 1,
        });
      }
      setNewInterventions(newInts);
      setExistingInterventions(prevState => {
        prevState[task.id] = draftFC?.interventions ?? [];
        return prevState;
      });
      setGroupedFillCoordinationIds(prevState => {
        prevState[task.id] = draftFC?.groupedFCTaskIds ?? [];
        return prevState;
      });

      const matchingTherapy = therapies[task.therapy_id];
      const initialValues = buildPerTaskInfo(fillCycles, matchingTherapy, task);

      props.change(fieldNamePerTask('next_needsby_date', task), initialValues.nextNeedsByDate);
      props.change(fieldNamePerTask('on_hand_qty', task), initialValues.onHandQty);
      props.change(fieldNamePerTask('dispense_qty', task), initialValues.dispenseQty);
      props.change(fieldNamePerTask('service_group_id', task), initialValues.serviceGroupId);
      props.change(
        fieldNamePerTask('days_supply', task),
        draftFC?.daysSupply ?? initialValues.daysSupply,
      );
      const initialNeedsByDate = draftFC?.needsByDate
        ? moment.utc(draftFC?.needsByDate)
        : initialValues.needsbyDate;
      props.change(fieldNamePerTask('needsby_date', task), initialNeedsByDate);

      const coldChainSelected = coldChainDisabled(
        props.tasks[0],
        allTherapies,
        props.tasks.slice(1).map(task => {
          const taskTherapy = therapies[task.therapy_id];
          return { task: task, therapy: taskTherapy };
        }),
      );

      props.change(nameOfFormFields('cold_chain_packing_required'), coldChainSelected);
    };

    const addedTasks = props.tasks.filter(task => !selectedTaskIds.has(task.id));
    const addedTaskUpdates = addedTasks.map(async task => updateAddedTask(task));
    Promise.all(addedTaskUpdates);

    setSelectedTaskIds(prevSelectedTaskIds => {
      const addedTaskIds = addedTasks.map(task => task.id);
      return new Set(Array.from(prevSelectedTaskIds).concat(addedTaskIds));
    });
  }, [props.tasks]);

  React.useEffect(() => {
    const updateRemovedTask = async (removedTaskId: number) => {
      setExistingInterventions(prevState => {
        delete prevState[removedTaskId];
        return prevState;
      });
      setGroupedFillCoordinationIds(prevState => {
        delete prevState[removedTaskId];
        return prevState;
      });
    };

    const removedTaskIds = Array.from(selectedTaskIds).filter(
      taskId => !props.tasks.map(task => task.id).includes(taskId),
    );
    const removedTaskUpdates = removedTaskIds.map(async taskId => updateRemovedTask(taskId));
    Promise.all(removedTaskUpdates);

    setSelectedTaskIds(prevSelectedTaskIds => {
      const remainingTaskIds = Array.from(prevSelectedTaskIds).filter(
        taskId => !removedTaskIds.includes(taskId),
      );
      return new Set(remainingTaskIds);
    });
  }, [props.tasks]);

  // Ensure Next NeedsBy date stays updated
  React.useEffect(() => {
    props.tasks.forEach(task => {
      const taskId = task.id;
      const daysNumber = Number(props.currentValues.days_supply?.[taskId] ?? 0);
      const qtyNumber = Number(props.currentValues.on_hand_qty?.[taskId] ?? 0);
      const nextNeedsByDate = moment.utc(calculateNextNeedsByDate(daysNumber, qtyNumber));
      if (
        !isTherapyInMedSync(therapies[task.therapy_id]) &&
        !nextNeedsByDate.isSame(props.currentValues.next_needsby_date?.[taskId], 'day')
      ) {
        props.change(nameOfFormFieldsPerTask('next_needsby_date', task), nextNeedsByDate);
      }
    });
  }, [
    props.currentValues.days_supply,
    props.currentValues.on_hand_qty,
    props.change,
    props.tasks,
    props,
    therapies,
  ]);

  // Ensure NeedsBy date stays updated when on hand qty is updated
  // Ensure onHandQty stays updated when needs by date is updated
  const onHandQtyRef = useRef<Record<number, number> | undefined>(props.initialValues.on_hand_qty);
  const needsByRef = useRef<Record<number, moment.Moment> | undefined>(
    props.initialValues.needsby_date,
  );
  React.useEffect(() => {
    props.tasks.forEach(task => {
      const taskId = task.id;
      const qtyNumber = Number(props.currentValues.on_hand_qty?.[taskId] ?? 0);
      const prevQtyNumber = Number(onHandQtyRef.current?.[taskId] ?? 0);
      const todayStartOfDay = moment.utc().startOf('day');
      const needsByDate = props.currentValues.needsby_date?.[taskId] ?? todayStartOfDay;
      const prevNeedsByDate = needsByRef.current?.[taskId] ?? todayStartOfDay;

      if (qtyNumber !== prevQtyNumber) {
        const needsByDate = moment.utc(calculateNeedsByDate(qtyNumber));
        if (!needsByDate.isSame(props.currentValues.needsby_date?.[taskId], 'day')) {
          props.change(nameOfFormFieldsPerTask('needsby_date', task), needsByDate);
        }
      } else if (!needsByDate.isSame(prevNeedsByDate, 'day')) {
        const onHandQty = calculateOnHandQty(needsByDate.toDate());
        if (onHandQty !== prevQtyNumber) {
          props.change(nameOfFormFieldsPerTask('on_hand_qty', task), onHandQty);
        }
      }
    });
    onHandQtyRef.current = props.currentValues.on_hand_qty;
  }, [
    props.currentValues.on_hand_qty,
    props.currentValues.needsby_date,
    props.change,
    props.tasks,
  ]);

  React.useEffect(() => {
    if (props.currentValues.address?.value) {
      const addressSame = props.currentValues.address.label !== addressValue;
      const shipDateSame = props.currentValues.ship_date?.isSame(shipDate, 'day');

      if (!addressSame || !shipDateSame) {
        updateWeatherBasedOnAddress(
          props.currentValues.address.label,
          props.currentValues.ship_date,
          props.change,
        );
      }

      if (!addressSame) {
        setAddressValue(props.currentValues.address!.label);
      }

      if (!shipDateSame) {
        setShipDate(props.currentValues.ship_date);
      }
    }
  }, [props.currentValues.address, props.currentValues.ship_date]);
  // #endregion

  const flattenedInterventions = Object.values(existingInterventions).reduce(
    (flattenedArray, interventionForFC) => flattenedArray.concat(interventionForFC),
    [],
  ); // flat() is not supported yet by our ts compliler
  const flattenedGroupedFCIds = useMemo(
    () =>
      Object.values(groupedFillCoordinationIds).reduce(
        (flattenedArray, groupedFCIdsForFC) => flattenedArray.concat(groupedFCIdsForFC),
        [],
      ),
    [groupedFillCoordinationIds],
  ); // flat() is not supported yet by our ts compliler

  let confirmationPanelSubmitButtonText = '';
  if (props.selectedStatusId === FillCoordinationStatus.In_Progress) {
    confirmationPanelSubmitButtonText = 'Save as In Progress';
  } else if (windowFeatureIsEnabled('fdc_statuses')) {
    confirmationPanelSubmitButtonText = 'Save FC';
  } else {
    confirmationPanelSubmitButtonText = 'Save & Preview FC';
  }

  const isSubmitDisabledForDurCompletion =
    props.isDurRequiredForFc && props.selectedStatusId !== FillCoordinationStatus.In_Progress;
  // #region render
  return (
    <>
      {props.submitting ? (
        <Backdrop sx={{ color: '#fff', zIndex: globalZIndex.loadingBlock }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      <Grid container>
        {/* FC Form */}
        <FillCoordinationEditReduxForm
          handleOnSelectedRow={props.handleOnSelectedRow}
          patient={patient}
          therapies={therapies}
          tasks={[props.tasks[0]]}
          allTasks={props.tasks}
          fcTaskIds={props.tasks.map(task => task.id)}
          userData={userData}
          componentMode="changeStatus"
          contactList={Object.keys(contactList).length ? contactList : {}}
          statuses={statuses}
          deliveryMethodVal={props.currentValues.preferred_rx_delivery_method}
          totalCopayAmount={calculateTotalCopayAmount(copayAmountList)}
          methodIsChartReview={props.currentValues.method === MethodOptions.ChartReview}
          form={props.form}
          otcProvider={otcProvider}
          selectedStatus={props.selectedStatusId}
          setShippingAddressDialogSettings={setShippingAddressDialogSettings}
          setShippingAddressIsNotPatientPreferred={setShippingAddressIsNotPatientPreferred}
          setShowSendSecureLinkWarning={setShowSendSecureLinkWarning}
          classes={(props as any).classes}
          siblingTasks={props.tasks.slice(1).map(task => {
            const taskTherapy = therapies[task.therapy_id];
            return { task: task, therapy: taskTherapy };
          })}
          haveNewAllergies={Boolean(props.currentValues?.new_allergies)}
          haveNewInfections={Boolean(props.currentValues?.new_infections)}
          selectedAncillarySupplies={props.currentValues?.ancillary_supplies}
          changeAncillarySupplies={changeAncillarySupplies}
          handleExpectedDeliveryDateChange={handleExpectedDeliveryDateChange}
          showTherapiesNdcsWarning={showTherapiesNdcsWarning}
          areMedicationChanges={Boolean(props.currentValues?.changes_in_medication)}
          haveNewSideEffects={Boolean(props.currentValues?.side_effects)}
          changeNewInterventionsHandler={changeNewInterventionsHandler}
          newInterventions={newInterventions}
          patientMissedDoses={Boolean(props.currentValues?.patient_missed_doses)}
          paymentMethodOnFileSelected={Boolean(props.currentValues?.payment_method_on_file)}
          upsPackagingTypeSelected={
            props.currentValues?.ups_packaging_type === undefined
              ? { value: 2, label: 'Customer Supplied Package' }
              : props.currentValues?.ups_packaging_type
          }
          patientQuestionsDisableNo={props.initialValues?.patient_questions ?? false}
          hospitalVisitDisableNo={props.initialValues?.hospital_visit ?? false}
          existingInterventions={flattenedInterventions}
          groupedFCIds={flattenedGroupedFCIds}
          onSelectTaskIds={props.onSelectTaskIds}
          initialValues={props.initialValues}
          changeSelectedStatus={props.changeSelectedStatus}
          closeTab={handleCancel}
          setRemovedFCIds={setRemovedFCIds}
          removedFCIds={removedFCIds}
        />

        {/* Document Upload */}
        {props.showUploadFile && (
          <>
            {!pendingDocument && (
              <Grid item xs={6}>
                <SelectFileForm
                  acceptedFileExtensions={acceptedFileExtensions}
                  handleRejectedDrop={() => fileRejected()}
                  handleAcceptedDrop={fileAccepted}
                  handleFilePaste={(e: any) => filePaste(e)}
                  errorMessage={fileErrors.fileErrorMessage}
                  errorCaption={fileErrors.fileErrorCaption}
                />
              </Grid>
            )}

            {pendingDocument && (
              <Grid item xs={6}>
                <FileSelectedForm
                  documentLabels={defaultDocumentLabels.map(docLabel => ({
                    label: docLabel.label,
                    value: docLabel.label,
                  }))}
                  submitButtonText="Upload"
                  fileName={pendingDocument.fileName}
                  fileExtension={pendingDocument.fileExtension}
                  onCancel={() => fileUploadCancel()}
                  formSubmit={() => fileUploadSubmit()}
                  isProcessing={uploadProcessing}
                  errorMessage={uploadErrorMessage}
                  form={fileUploadFormName()}
                />
              </Grid>
            )}

            {Boolean(uploadedDocuments?.length) && (
              <Grid container>
                {uploadedDocuments.map(document => {
                  const documentData = {
                    filename: document.file_name,
                    labels: document.labels,
                    tags: [NoteTagLabels.FC],
                  };
                  return <PendingDocument documentData={documentData} />;
                })}
              </Grid>
            )}

            {pendingDocumentLoss && (
              <ConfirmationDialogPanel
                open={pendingDocumentLoss}
                title={PENDING_DOCUMENT_LOSS.header}
                content={
                  <>
                    <Typography gutterBottom>{PENDING_DOCUMENT_LOSS.body}</Typography>
                    {lostDataDialogSettings?.messages?.map(msg => {
                      return <Typography>{msg}</Typography>;
                    })}
                  </>
                }
                cancelText="Cancel"
                continueText="Continue"
                onCancel={lostDataDialogSettings?.dialogCancel}
                onContinue={lostDataDialogSettings?.dialogContinue}
              />
            )}
          </>
        )}

        {showSendSecureLinkWarning.show ? (
          <ConfirmationDialogPanel
            open
            content={
              <Typography gutterBottom className={(props as any).classes.shippingAddressText}>
                Bundles with all non-specialty therapies should contain at least one non-PRN
                therapy.
              </Typography>
            }
            showCancelButton
            cancelText="Cancel"
            continueText="Send Secure Link Anyway"
            onCancel={showSendSecureLinkWarning?.dialogCancel}
            onContinue={showSendSecureLinkWarning?.dialogContinue}
          />
        ) : null}

        {shippingAddressIsNotPatientPreferred && !statusIsWaitingPatientResponse ? (
          <ConfirmationDialogPanel
            open
            content={
              <Typography gutterBottom className={(props as any).classes.shippingAddressText}>
                {shippingAddressDialogSettings?.messages}
              </Typography>
            }
            showCancelButton={shippingAddressDialogSettings?.showCancelButton}
            cancelText="Cancel"
            continueText={shippingAddressDialogSettings?.continueText || 'Continue'}
            onCancel={shippingAddressDialogSettings?.dialogCancel}
            onContinue={shippingAddressDialogSettings?.dialogContinue}
          />
        ) : null}

        {patientChoicesViolation.show ? (
          <ConfirmationDialogPanel
            open
            content={
              <Typography gutterBottom className={(props as any).classes.shippingAddressText}>
                The patient requested removal of the following therapies that are still in the
                bundle:
                {patientChoicesViolation?.therapies?.map(item => (
                  <p>{item}</p>
                ))}
              </Typography>
            }
            showCancelButton={patientChoicesViolation.showCancelButton}
            cancelText="Cancel"
            continueText="Continue"
            onCancel={patientChoicesViolation?.dialogCancel}
            onContinue={patientChoicesViolation?.dialogContinue}
          />
        ) : null}

        {shippingAddressIsNotPatientPreferred && !statusIsWaitingPatientResponse ? (
          <ConfirmationDialogPanel
            open
            content={
              <Typography gutterBottom className={(props as any).classes.shippingAddressText}>
                {shippingAddressDialogSettings?.messages}
              </Typography>
            }
            showCancelButton={shippingAddressDialogSettings?.showCancelButton}
            cancelText="Cancel"
            continueText={shippingAddressDialogSettings?.continueText || 'Continue'}
            onCancel={shippingAddressDialogSettings?.dialogCancel}
            onContinue={shippingAddressDialogSettings?.dialogContinue}
          />
        ) : null}

        {/* Confirmation Panel */}
        <LineDivider />
        {statusIsWaitingPatientResponse ? (
          <ConfirmationPanel handleCancel={handleCancel} hideSubmit />
        ) : (
          <>
            {props.hasMissingKeys ? (
              <MissingResponses
                isPickUp={currentDeliveryMethod === DeliveryMethodValue.PickUp}
                formName={props.form}
              />
            ) : null}
            {formValues?.values?.preferred_rx_delivery_method &&
            formValues.values.preferred_rx_delivery_method.value !== 1 &&
            formValues.values.signature_required === 1 ? (
              <Grid
                container
                xs={12}
                sx={{
                  display: 'flex',
                  color: 'red',
                  height: '30px',
                  fontSize: '15px',
                  paddingTop: '8px',
                }}
                justifyContent="flex-end"
              >
                Signature Required is selected for this FC
              </Grid>
            ) : null}
            <ConfirmationPanel
              disableSubmit={
                props.submitting || isSubmitDisabledForDurCompletion || props.hasMissingKeys
              }
              disableCancel={props.submitting}
              handleSubmit={submitWithPrechecks}
              handleCancel={handleCancel}
              submitButtonText={confirmationPanelSubmitButtonText}
              loadingText="Saving"
              isLoading={props.submitting}
            />
          </>
        )}
      </Grid>
    </>
  );
  // #endregion render
};

const mapStateToProps = (state: IState, props: IProps): IStateProps => {
  const selector = formValueSelector(props.form);
  const upsPackagingTypes = state.lookups.upsPackagingType;
  const preferredRxDeliveryPharmacyId = state?.patient?.preferred_rx_delivery_pharmacy_id;
  const preferredRxDeliveryPharmacy = state?.pharmacies?.pharmacies.find(
    pharm => pharm.id === preferredRxDeliveryPharmacyId,
  );
  const internalPharmacies = state?.pharmacies?.pharmacies;

  const initialValues: any = buildInitialValues(
    props.tasks,
    { serviceGroups: state.lookups.serviceGroups },
    state.patient,
    state.therapies.data,
    state.contactList,
    state.fillCycles,
    [],
    upsPackagingTypes,
    preferredRxDeliveryPharmacy,
    internalPharmacies,
  );

  const currentValues = {
    address: selector(state, nameOfFormFields('address')),
    changes_in_medication: selector(state, nameOfFormFields('changes_in_medication')),
    new_medical_conditions: selector(state, nameOfFormFields('new_medical_conditions')),
    copay_amount: selector(state, nameOfFormFields('copay_amount')),
    days_supply: selector(state, nameOfFormFields('days_supply')),
    method: selector(state, nameOfFormFields('method')),
    needsby_date: selector(state, nameOfFormFields('needsby_date')),
    new_allergies: selector(state, nameOfFormFields('new_allergies')),
    new_infections: selector(state, nameOfFormFields('new_infections')),
    next_needsby_date: selector(state, nameOfFormFields('next_needsby_date')),
    on_hand_qty: selector(state, nameOfFormFields('on_hand_qty')),
    patient_missed_doses: selector(state, nameOfFormFields('patient_missed_doses')),
    preferred_rx_delivery_method:
      selector(state, nameOfFormFields('preferred_rx_delivery_method')) ||
      initialValues.preferred_rx_delivery_method,
    ship_date: selector(state, nameOfFormFields('ship_date')),
    side_effects: selector(state, nameOfFormFields('side_effects')),
    ancillary_supplies: selector(state, nameOfFormFields('ancillary_supplies')),
    payment_method_on_file: selector(state, nameOfFormFields('payment_method_on_file')),
    dispense_qty: selector(state, nameOfFormFields('dispense_qty')),
    service_group_id: selector(state, nameOfFormFields('service_group_id')),
    ups_packaging_type: selector(state, nameOfFormFields('ups_packaging_type')),
    hospital_visit: selector(state, nameOfFormFields('hospital_visit')),
    patient_questions: selector(state, nameOfFormFields('patient_questions')),
    signature_required: selector(state, nameOfFormFields('signature_required')),
  };

  return {
    internalPharmacies,
    initialValues,
    currentValues,
    isDurRequiredForFc: selectIsDurRequired(state),
    hasMissingKeys: !!selectMissingRequiredKeys(state, props.form)?.length,
  };
};

Complete.displayName = 'Complete';

export default compose<Props, any>(
  withStyles(styles as any),
  connect(mapStateToProps, {
    updatePatientPreferences,
    updateTherapiesDispensingPharmacyInRedux,
    updateFCDispensingPharmacyInRedux,
  }),
  createMemoReduxForm(),
)(Complete);
