import React, { FC as FCReact, useMemo } from 'react';
import { buildQaIdProp } from 'utils/build-qa-id';
import { convertToArborDate } from 'models/time/arbor-date';
import Communication from 'components/note/communication';
import Intervention from 'components/note/intervention';
import Incident from 'components/note/incident';
import { FC, CSL, FA, PA, QRE, AR, RS, DUR, THERAPY_MED_SYNC } from 'constants/index';
import { ReactComponent as InterventionIcon } from 'lib/logos/intervention.svg';
import { ReactComponent as IncidentIcon } from 'lib/logos/incident.svg';
import { counselingTaskTypes, fcInProgressReasons, noteTagTypes } from 'constants/lists';
import { getDisplayNotes } from 'services/utils/note-service';
import { findMatchingDocuments } from 'services/utils/document-service';
import {
  CAIcon,
  CommunicationIcon,
  PerProtocolIcon,
  PRNIcon,
  APIcon,
  getCMIcon,
} from 'components/icons/icons';
import DisplayOrderForm from 'containers/shipping/display-order-form';
import { TaskCategory } from 'interfaces/enums/TaskCategory';
import { useDispatch, useSelector } from 'react-redux';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { getStatusNameByStatusId } from 'services/utils/task-service';
import { integrateUpdatedTasks } from 'actions/action-tasks';
import {
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  Box,
  IconButton,
  Tooltip,
  Grid,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ITherapy } from 'interfaces/redux/ITherapy';
import AddIcon from '@mui/icons-material/Add';
import { updateDocumentBar } from 'actions/action-uploaded-documents';
import { openCommunicationNotesSideBar } from 'actions/action-notes';
import { NoteBarTrigger } from 'constants/enums';
import { useFillCycleNumber } from 'hooks/useFillCycleNumber';

import { FillCoordinationStatus } from 'interfaces/enums/TaskStatuses/FillCoordinationStatus';
import { MedSyncTooltipIcon } from 'containers/patient/med-sync/components/medSyncIcon';
import { windowFeatureIsEnabled } from 'config/window-features';
import { selectPatientId, selectPatientName } from 'hooks/usePatient';
import { useDeepDiffSelector } from 'utils/deep-diff-props';
import { SecureFCClient } from '../../../../clients/secure-fc-client';
import { notifyError } from '../../../../actions/action-notifications';
import { NeedsByDateDialogForm } from '../forms/needsby-date-task-therapy';
import {
  NoteTooltip,
  NoteTooltipContent,
  NoteContent,
  DosingInformationTooltip,
  DosingInformationTooltipContent,
} from './tooltips';
import { DosingPillIcon } from '../icons';
import { styles } from './table.styles';
import { ITasksTableRowProps, IDiagnosesObj } from './interfaces';
import {
  FollowUpDateDialogForm as CompletedDateDialogForm,
  FollowUpDateDialogForm,
} from '../forms/followup-date-task-form';
import { MedSyncDisplayStatus } from '../../med-sync/common';
import { getIsTaskKeySelected } from '../selectors';

const emptyDateLabel = '-';

const wasPartOfAPriorBundle = (task: any) => task?.taskType === FC && task.secure_link_id < 0;

const qaIdProp = buildQaIdProp('tasks-table');

// eslint-disable-next-line react/function-component-definition
const TasksTableRow: FCReact<ITasksTableRowProps> = (props): JSX.Element => {
  const {
    classes,
    taskKey,
    index,
    onSelectedRow,
    disableUpdateDates,
    updatedTasks,
    setTriggerRowUpdate,
    version,
    isChecked,
  } = props;

  const dispatch = useDispatch();
  const isTaskSelected = useTypedSelector(
    state => isChecked || getIsTaskKeySelected(state, taskKey),
  );
  const task = useTypedSelector(state => state.tasks.data[taskKey] ?? null);
  const therapies = useTypedSelector(state => state.therapies.data);
  const serviceGroups = useTypedSelector(state => state.lookups.serviceGroups);
  const lookups = useTypedSelector(state => state.lookups);
  const tasksStatuses = useTypedSelector(state => state.taskStatuses.statuses);
  const { users } = lookups;
  const { allPinnedNotes, allNonPinnedNotes, allNotes } = useDeepDiffSelector(state => {
    const noteState = state.notes?.notes ?? {
      allPinnedNotes: [],
      allNonPinnedNotes: [],
      allNotes: [],
    };
    return {
      allPinnedNotes: noteState.allPinnedNotes,
      allNonPinnedNotes: noteState.allNonPinnedNotes,
      allNotes: noteState.allNotes,
    };
  });
  const uploadedDocuments = useTypedSelector(state => state.uploadedDocuments);
  const documents = uploadedDocuments.documents || [];
  const patientId = useSelector(selectPatientId);
  const { patientFullName } = useSelector(selectPatientName);
  const therapy: ITherapy =
    therapies?.[task.therapy_id] || therapies?.[task.therapyInitialId as unknown as number] || {};
  const tagType = noteTagTypes.find(tag => tag.label === task.taskType);
  const tagTypeId = tagType ? tagType.value : null;
  const taskStatus =
    task?.task_status_text || getStatusNameByStatusId(task.status_id, tasksStatuses);

  const addFCToBundle = async (task: any) => {
    const payload = {
      fc_task_id: task.id,
    };
    try {
      const response = await SecureFCClient.addFCBackToBundle(payload);
      dispatch(integrateUpdatedTasks(response.data.updatedTasks));
      const newTask = response.data.updatedTasks.find(
        (task: any) => task.id === payload.fc_task_id,
      );
      setTimeout(() => onSelectedRow(newTask), 1);
    } catch (error) {
      dispatch(notifyError('Error adding the FC to the bundle'));
      console.log('Error adding the FC to the bundle: ', error);
    }
  };

  const displayNotes = useMemo(
    () =>
      getDisplayNotes(
        allPinnedNotes,
        allNonPinnedNotes,
        tagTypeId,
        task.id,
        users,
        serviceGroups,
        2,
      ),
    [allNonPinnedNotes, allPinnedNotes, serviceGroups, tagTypeId, task.id, users],
  );

  const allNotesForTask = useMemo(
    () =>
      allNotes.filter((n: any) =>
        n.tags.some((t: any) => t?.tag_type_id === tagTypeId && t?.resource_id === task.id),
      ),
    [allNotes, tagTypeId, task.id],
  );

  const taskDocuments =
    findMatchingDocuments(documents, [{ tagTypeId: tagTypeId, resourceId: task.id }]) || [];

  const handleOnShowDocuments = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(
      updateDocumentBar({
        display: true,
        taskIdResourceIds: [{ tagTypeId: tagTypeId, resourceId: task?.id }],
        tagTypeId: tagTypeId,
        resourceId: task?.id,
        drugName: therapy?.drug_name || '',
        tagTypeLabel: task?.type,
        update: false,
      }),
    );
  };

  const handleOnShowNotes = () => {
    const payload = {
      noteBar: {
        display: true,
        tagTypeId: tagType?.value,
        tagResourceId: task.id,
        trigger: NoteBarTrigger.Notes,
        tag: task.taskType,
        showAllNotes: false,
      },
      therapy,
    };

    dispatch(openCommunicationNotesSideBar(payload));
  };

  const taskIsOverdue = convertToArborDate(task.followup_dt, true).isBeforeToday();
  const taskIsComplete =
    task.status_category_id === TaskCategory.Done ||
    task.status_category_id === TaskCategory.Archived;

  let rowStyling = index % 2 === 0 ? classes.evenRow : classes.oddRow;
  if (taskIsComplete) {
    rowStyling = classes.completedRow;
  }
  if (isTaskSelected) {
    rowStyling = classes.highlightRow;
  }

  let taskLabelStyling = classes.inProgressText;
  if (taskIsOverdue) {
    taskLabelStyling = classes.outstandingText;
  }

  const taskIsLinkedToTherapy =
    Boolean(task.therapy_id || task.therapy_ids) && task.taskType !== AR && task.taskType !== DUR;
  let arrTherapyFullNames = ['-'];
  let therapiesIdArrForTask: string[] = [];
  if (taskIsLinkedToTherapy) {
    if ((task && task.therapy_ids) || (therapy && therapy.id)) {
      therapiesIdArrForTask = (task.therapy_ids || `${therapy ? therapy.id : ''}`)
        .split(',')
        .filter((id: any) => (therapies || {})[Number(id)]);
      arrTherapyFullNames = therapiesIdArrForTask.map(id => {
        const therapyInstance = therapies[Number(id)];
        const drugName = therapyInstance?.drug_name;
        const therapyDosage =
          therapyInstance && therapyInstance.strength && therapyInstance.strength_unit_of_measure
            ? `- ${therapyInstance.strength} ${therapyInstance.strength_unit_of_measure}`
            : '';
        return `${drugName || ''}${therapyDosage ? ` ${therapyDosage}` : ''}`;
      });
    }
  } else if (task.taskType === AR) {
    const arDrugs = task.drugs;
    arrTherapyFullNames = arDrugs?.map(
      (drug: any) => `${drug.drug_name} ${drug.strength} - ${drug.specialty_type}`,
    ) || ['-'];
  }
  const baseTherapyFullName = arrTherapyFullNames[0];
  const therapiesForTooltip = arrTherapyFullNames.slice(1).join(', ') || null;

  const taskStatusStyle = taskIsComplete ? classes.completedText : undefined;
  const followupDate = task?.followup_dt
    ? convertToArborDate(task.followup_dt, true).getUtcDate(true, 'M/D/YY')
    : emptyDateLabel;
  const completedDateField = task?.updated;
  const completedDate = completedDateField
    ? convertToArborDate(completedDateField, true).getUtcDate(true, 'M/D/YY')
    : emptyDateLabel;
  const [needsByDateUTC, isPerProtocol] = useFillCycleNumber(
    task.therapy_id,
    task.fill_cycle_number,
  );
  const needsByDate = React.useMemo(
    () =>
      task?.taskType === 'FDC'
        ? convertToArborDate(task?.due_date, true).getUtcDate(true, 'M/D/YY')
        : convertToArborDate(needsByDateUTC, true).getUtcDate(true, 'M/D/YY'),
    [needsByDateUTC],
  );
  const actionIconStyling = taskIsComplete
    ? classes.actionIconTaskInProgress
    : classes.actionIconTaskCompleted;
  const noteCount = allNotesForTask.length > 0 ? allNotesForTask.length : '-';
  const isInMedSync = therapy?.in_med_sync === THERAPY_MED_SYNC.IN_MED_SYNC;
  const showNBD = task.taskType === 'FC' || (task.taskType === 'FDC' && task.due_date != null);
  const dosingInformation: string = (therapy && therapy.dosis_regimen) || '';
  const getTaskLabel = () => {
    let taskPostscript = '';
    switch (task.taskType) {
      case FC:
        if (task.fill_cycle_number) {
          taskPostscript = ` #${task.fill_cycle_number}`;
        }
        break;
      case CSL:
        const cslTaskTypeName = counselingTaskTypes.find(
          type => type.id === task.counseling_type,
        )?.name;
        if (cslTaskTypeName) {
          taskPostscript = ` - ${cslTaskTypeName}`;
        }
        break;
      case FA:
      case PA:
        taskPostscript = ` - ${task.is_renewal ? 'Renewal' : 'New'}`;
        break;
      case QRE:
        const categoryName = lookups.incidentCategories.find(
          item => item.id === task.category_id,
        )?.name;
        taskPostscript = categoryName ? ` - ${categoryName}` : '';
        break;
      default:
        break;
    }
    return `${task.taskType}${taskPostscript}`;
  };

  const therapyDiagnosisTooltip = () => {
    let diagnosisDescriptions: IDiagnosesObj[] = [];
    if (therapy) {
      diagnosisDescriptions = [
        {
          therapy_name: therapy.drug_name,
          diagnoses: [
            therapy.diagnosis_description,
            therapy.secondary_diagnosis_description,
            therapy.tertiary_diagnosis_description,
          ],
        },
      ];
    }
    if (task.therapy_ids && task.therapy_ids.split(',').length > 1) {
      const taskTherapyIdArr = therapy
        ? therapiesIdArrForTask.filter(t => Number(t) !== therapy.id).map(i => Number(i))
        : therapiesIdArrForTask.map(i => Number(i));
      const taskTherapyArr = taskTherapyIdArr.map(i => therapies[Number(i)]);
      const therapiesDiagnoses: IDiagnosesObj[] = taskTherapyArr.map((t: ITherapy) => {
        const therapyDiagnosesArr: string[] = [];
        therapyDiagnosesArr.push(t.diagnosis_description || '');
        therapyDiagnosesArr.push(t.secondary_diagnosis_description || '');
        therapyDiagnosesArr.push(t.tertiary_diagnosis_description || '');
        return { therapy_name: t.drug_name, diagnoses: therapyDiagnosesArr };
      });
      diagnosisDescriptions = [...diagnosisDescriptions, ...therapiesDiagnoses];
    }
    return (
      <>
        {diagnosisDescriptions.map((diagnosesObj: IDiagnosesObj) => (
          <React.Fragment key={diagnosesObj.diagnoses.join('-')}>
            <Typography className={classes.diagnosisTextBold}>
              {`${diagnosesObj.therapy_name}:`}
            </Typography>
            {diagnosesObj.diagnoses
              .filter((s: string) => !!s)
              .map((d: string) => (
                <Typography key={d} className={classes.diagnosisText}>{`• ${d}`}</Typography>
              ))}
          </React.Fragment>
        ))}
      </>
    );
  };

  const showTherapyTooltip = therapy && ![AR, RS, DUR].includes(task.taskType);
  const showAddToPriorBundle = useMemo(() => wasPartOfAPriorBundle(task), [task]);

  React.useEffect(() => {
    if (updatedTasks?.some(t => t.id === task.id && t.taskType === task.taskType)) {
      setTriggerRowUpdate([]);
    }
  }, [setTriggerRowUpdate, task.id, task.taskType, updatedTasks]);
  return (
    <TableRow {...qaIdProp(`row-${task.type}-${task.id}`)} className={rowStyling}>
      <TableCell align="left" {...qaIdProp('select')}>
        <Checkbox
          color="primary"
          disableRipple
          checked={isTaskSelected}
          onClick={() => onSelectedRow(task)}
        />
      </TableCell>
      <TableCell align="left" {...qaIdProp('type')}>
        <Typography className={taskLabelStyling}>
          {getTaskLabel()}
          {task && task.taskType === 'FDC' ? (
            <DisplayOrderForm data={task} patientId={patientId} />
          ) : null}
          {showAddToPriorBundle ? (
            <Tooltip title="Add back to Secure Link Bundle">
              <div className={classes.plusSignButton} onClick={() => addFCToBundle(task)}>
                +
              </div>
            </Tooltip>
          ) : null}
        </Typography>
      </TableCell>
      <TableCell align="left" {...qaIdProp('therapy')}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.therapyNameFull}>
            {therapy || task.therapy_ids ? (
              <Tooltip title={therapyDiagnosisTooltip()}>
                <span>{baseTherapyFullName}</span>
              </Tooltip>
            ) : (
              <span>{baseTherapyFullName}</span>
            )}
            {therapiesForTooltip && (
              <Tooltip title={therapiesForTooltip} className={classes.therapyEllipsis}>
                <span>...</span>
              </Tooltip>
            )}
            {Boolean(therapy?.one_time_fill) &&
              task.taskType === 'FC' &&
              task.fill_cycle_number === 1 && (
                <Tooltip title="One-Time Fill">
                  <Typography component="span" className={`${classes.therapyTag} OTF`}>
                    OTF
                  </Typography>
                </Tooltip>
              )}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {!!therapy?.adherence_packaging && getTaskLabel().includes(FC) ? (
              <Tooltip title="Adherence Package Required" placement="bottom-start">
                <span>
                  <APIcon className={classes.prnIcon} />
                </span>
              </Tooltip>
            ) : null}
            <DosingInformationTooltip
              title={
                showTherapyTooltip ? (
                  <DosingInformationTooltipContent
                    dosingInformation={{
                      ...therapy,
                      dosis_regimen: task.dosis_regimen || therapy.dosis_regimen,
                    }}
                  />
                ) : (
                  ''
                )
              }
            >
              <DosingPillIcon
                fontSize="small"
                className={dosingInformation || classes.dosingIconDisabled}
              />
            </DosingInformationTooltip>
            {windowFeatureIsEnabled('med_sync') && therapy?.in_med_sync ? (
              <MedSyncTooltipIcon
                displayStatus={MedSyncDisplayStatus.Synchronized}
                tooltipTitle="Med Sync"
                size="sm"
              />
            ) : null}
            {therapy?.as_needed && getTaskLabel().includes(FC) ? (
              <PRNIcon className={classes.prnIcon} />
            ) : null}
            {therapy?.clinic_administered && getTaskLabel().includes(FC) ? (
              <CAIcon className={classes.prnIcon} />
            ) : null}
            {therapy?.controlled_substance_code
              ? getCMIcon(therapy?.controlled_substance_code, classes.prnIcon)
              : null}
            <Typography>{therapy?.administration_status}</Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell align="left" {...qaIdProp('status')}>
        {task.status_id === FillCoordinationStatus.In_Progress && task.in_progress_reason ? (
          <Tooltip
            title={
              <Grid padding={0.5}>
                <Typography className={taskStatusStyle}>Reason:</Typography>
                <Typography className={taskStatusStyle} fontWeight="bold">
                  {
                    fcInProgressReasons.find(
                      reason => reason.value === Number(task.in_progress_reason),
                    )?.label
                  }
                </Typography>
              </Grid>
            }
            placement="bottom-start"
          >
            <Typography className={taskStatusStyle}>{taskStatus}</Typography>
          </Tooltip>
        ) : (
          <Typography className={taskStatusStyle}>{taskStatus}</Typography>
        )}
      </TableCell>
      {version === 'active' ? (
        <TableCell align="left" {...qaIdProp('followup_dt')}>
          <Box display="flex" alignItems="center">
            {!disableUpdateDates && <FollowUpDateDialogForm tasks={[task]} iconButtonType />}

            <Typography sx={disableUpdateDates ? { paddingLeft: '30px' } : {}}>
              {followupDate}
            </Typography>
          </Box>
        </TableCell>
      ) : (
        <TableCell align="left" {...qaIdProp('completed_dt')}>
          <Box display="flex" alignItems="center">
            {!disableUpdateDates && <CompletedDateDialogForm tasks={[task]} iconButtonType />}

            <Typography sx={disableUpdateDates ? { paddingLeft: '30px' } : {}}>
              {completedDate}
            </Typography>
          </Box>
        </TableCell>
      )}
      {version === 'active' && (
        <TableCell align="left" {...qaIdProp('needsby_date')}>
          {showNBD && (
            <Box display="flex" alignItems="center">
              {!disableUpdateDates && !isInMedSync && (
                <NeedsByDateDialogForm therapy={therapy} iconButtonType />
              )}
              {isInMedSync ? (
                <Tooltip
                  title="Therapy is part of med sync, change the Anchor Date to move the NBD"
                  placement="top"
                >
                  <Typography sx={{ paddingLeft: '30px', cursor: 'help' }}>
                    {needsByDate}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography sx={disableUpdateDates ? { paddingLeft: '30px' } : {}}>
                  {needsByDate}
                </Typography>
              )}
              {isPerProtocol && needsByDate && <PerProtocolIcon />}
            </Box>
          )}
        </TableCell>
      )}

      <TableCell align="left" {...qaIdProp('actions')}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}
        >
          <Tooltip
            title={patientId && patientFullName ? 'Create a new patient contact note' : 'Loading'}
          >
            <span style={{ display: 'flex' }}>
              <Communication
                disabled={!patientId || !patientFullName}
                tagTypeId={tagTypeId}
                task={task}
                tagResourceId={task.id}
                buttonOverride={<CommunicationIcon />}
                selectedPatientId={patientId}
                tagName={patientFullName}
              />
            </span>
          </Tooltip>
          <Tooltip title={patientId ? 'Create a new intervention' : 'Loading'}>
            <span style={{ display: 'flex' }}>
              <Intervention
                disabled={!patientId}
                therapy={null}
                tagTypeId={tagTypeId}
                tagResourceId={patientId}
                serviceGroups={serviceGroups}
                taskTherapyId={task.therapy_id || null}
                taskType="patient"
                inheritedClass={actionIconStyling}
                buttonOverride={<InterventionIcon fontSize="small" />}
              />
            </span>
          </Tooltip>

          <Tooltip title={patientId ? 'Create a new QRE' : 'Loading'}>
            <span style={{ display: 'flex' }}>
              <Incident
                disabled={!patientId}
                therapy={therapy}
                tagTypeId={tagTypeId}
                tagResourceId={patientId}
                serviceGroups={serviceGroups}
                taskType="patient"
                buttonOverride={<IncidentIcon fontSize="small" className={actionIconStyling} />}
              />
            </span>
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell align="center" {...qaIdProp('notes')}>
        {displayNotes.length > 0 ? (
          <NoteTooltip title={<NoteTooltipContent displayNotes={displayNotes} />}>
            <IconButton className={classes.noteColumn} onClick={handleOnShowNotes} size="small">
              {noteCount ? <NoteContent displayNotes={displayNotes} /> : <AddIcon />}
            </IconButton>
          </NoteTooltip>
        ) : (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.emptyNoteColumn}
          >
            <IconButton onClick={handleOnShowNotes} size="small">
              <AddIcon />
            </IconButton>
          </Grid>
        )}
      </TableCell>
      <TableCell align="center" {...qaIdProp('documents')}>
        <IconButton onClick={handleOnShowDocuments} size="small">
          {taskDocuments?.length > 0 ? (
            <Typography>{taskDocuments?.length}</Typography>
          ) : (
            <AddIcon />
          )}
        </IconButton>
      </TableCell>
      {version === 'archived' && <TableCell align="center" />}
    </TableRow>
  );
  // #endregion renderers
};

const StyledTaskRow = withStyles(styles)(TasksTableRow);
export default StyledTaskRow;
